export default {
  data() {
    return {
    }
  },
  methods: {
    async createReservation() {
      if(!this.valid() || !this.profileValidation()){
        return false
      }
      let course_params = this.reservation.courses.map(course => {
        return {course_id: course.id};
      })
      let params = {
        reservation: {
          location_id: this.reservation.location_id,
          reservation_courses_attributes: course_params,
          start_at: this.reservation.start_time,
          end_at: this.reservation.end_time,
          comment: this.reservation.comment
        }
      }
      if (this.reservation.admin_id) {
        params["reservation"]["admin_reservations_attributes"] = [{admin_id: this.reservation.admin_id,
          specify: this.reservation.specify}]
      }
      if (Object.keys(this.reservation.user).length) {
        params["reservation"]["reservation_profile_attributes"] =
          {
            zipcode: this.reservation.user.zipcode,
            prefecture: this.reservation.user.prefecture,
            city: this.reservation.user.city,
            block: this.reservation.user.block,
            building: this.reservation.user.building,
            tel: this.reservation.user.tel,
            email: this.reservation.user.email,
            name: this.reservation.user.name
          }
      }

      await this.axios.post('/api/v1/reservations', params).then((response) => {
        this.loading = true
        setTimeout(() => {
          this.$toasted.show("予約を完了しました！", {
            theme: "bubble",
            type: "success",
            position: "top-center",
            duration: 2000
          })
        }, 200)

        setTimeout(() => {
          window.location = this.redirect_path ? this.redirect_path : "/"
        }, 2000)

      }).catch((error) => {
        let errors = []

        Object.entries(error.response.data.errors).forEach(([key, e]) => errors.push(e))
        errors = [...new Set(errors.flat())]
        errors.forEach((e) => {
          this.$toasted.show(e, {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          })
        })
      })
    },


  }
}
