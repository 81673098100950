<template>
  <div>
    <div class="row">
      <div class="col">
        <h5 class="text-black"><i class="fa fa-users"></i> スタッフを選ぶ</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="admins.length">
          <div class="row">
            <div class="col-12" v-for="admin in admins" :key="admin.id">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="row">
                      <div class="col-12 col-sm-4">
                        <img :src="admin.thumbnail" class="img-fluid" alt="プロフィール画像">
                      </div>
                      <div class="col-12 col-sm-8">
                        <div class="media-body">

                          <h3 class=" mt-0 mb-1 text-black">
                            {{ admin.position }} {{ admin.display_name }}
                          </h3>

                          <div class="bg-green my-3 p-3 text-black" v-if="admin.license">
                            <p class="mb-0 pb-0 font-15 pl-1">
                              <b>資格:</b> {{ admin.license }}
                            </p>
                          </div>

                          <div style="white-space: pre-wrap; line-height: 1;" class="mb-0 pb-0 font-15 pl-1 text-black"
                            v-if="admin.introduction" v-text="admin.introduction">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">

                    <div class="col-sm-6 offset-sm-6">
                      <button class="btn btn-primary btn-block btn-rounded" @click="toggleMainAdmin(admin.id)"
                        v-if="reservation.admin_id != admin.id">
                        <i class="fa fa-check"></i>
                        このスタッフを選択する
                      </button>

                      <button class="btn btn-light btn-block btn-rounded" @click="toggleMainAdmin(admin.id)" v-else>
                        <i class="fa fa-check"></i>
                        選択済み
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

        <div v-else>
          <div class="alert alert-danger">
            現在利用可能なスタッフの空きがありません。条件を変えて実行して下さい。
          </div>
          <button class="btn btn-primary btn-lg w-100" type="button" @click="backScreen">
            戻る
          </button>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'select-admins',
  props: {
    reservation: {
      type: Object,
      required: true
    },
    admins: {
      type: Array,
      required: true
    },
    // allAdmins: {
    //   type: Array,
    //   required: true
    // }
  },
  async mounted() {
  },
  methods: {
    toggleMainAdmin(admin_id) {
      if (this.reservation.admin_id == admin_id) {
        this.$set(this.reservation, 'admin_id', null);
      } else {
        this.$set(this.reservation, 'admin_id', admin_id);
      }

      if (!this.reservation.admin_id) {
        return
      }


      if (this.reservation.start_time) {
        this.$emit("fetchCourses")
        this.$emit("setScreen", "course");
      } else {
        this.$emit("fetchCourses");
        this.$emit("fetchSchedules");
        this.$emit("setScreen", "schedule");
      }
      this.$emit("setReservationData", this.reservation);
    },
    backScreen() {
      this.$emit('backScreen')
    }
  },
  computed: {
    // mainAdmin() {
    //   return this.admins.filter(admin => admin.user_role == '担当');
    // },
    // supportAdmin() {
    //   return this.admins.filter(admin => admin.user_role == '補助者');
    // }
  }
}
</script>
