<template>
  <div>
    <div class="row">
      <!-- <div class="col-3 text-center">
        <button type="button" class="py-3 w-100 btn btn-outline-primary">
          <b>1.店舗<span class="d-none d-md-inline-block">を選ぶ</span></b>
        </button>
      </div>

      <div class="col-3 text-center">
        <button type="button" class="py-3 w-100 btn btn-outline-primary">
          <b>2.<span class="d-none d-md-inline-block">スタッフ/</span>日程<span class="d-none d-md-inline-block">を選ぶ</span></b>
        </button>
      </div>

      <div class="col-3 text-center">
        <button type="button" class="py-3 w-100 btn btn-outline-primary">
          <b>3.コース<span class="d-none d-md-inline-block">を選ぶ</span></b>
        </button>
      </div>

      <div class="col-3 text-center">
        <button type="button" class="py-3 w-100 btn btn-outline-primary">
          <b>4.予約者<span class="d-none d-md-inline-block">情報</span></b>
        </button>
      </div> -->

      <div class="col-12" v-if="screenHistory.length > 1">
        <button class="btn btn-light" @click="backScreen">
          <i class="fas fa-chevron-left"></i>
          戻る
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'step-nav',
  props: {
    reservation: {
      type: Object,
      required: true
    },
    screenHistory: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectTab: ''
    }
  },
  methods: {
    getClassNavi(tab) {
      return this.selectTab != tab ? 'w-100 btn btn-outline-primary' : 'w-100 btn btn-primary'
    },
    backScreen() {
      this.$emit('backScreen')
    }
  }
}
</script>
