import Vue from 'vue/dist/vue.esm'
import "vuetify/dist/vuetify.min.css";
import axios from './src/plugins/axios'
import vuetify from './src/plugins/vuetify'
import './src/styles/stylesheet.css'

import ElementUI from 'element-ui'
import           'element-ui/lib/theme-chalk/index.css'
import locale    from 'element-ui/lib/locale/lang/ja'

// components
import ShiftCalendar        from './src/components/admin/shift_tables/Calendar.vue';
import ReservationCalendar  from './src/components/admin/reservations/Calendar.vue';
import ATypeCalendar        from './src/components/admin/a_types/Calendar.vue';
import UserReservation      from './src/components/user/reservations/UserReservation.vue'
import UserBooking          from './src/components/user/bookings/UserBooking.vue'
import LocationScheduleForm from './src/components/admin/location_schedules/Form.vue'

import Toasted from 'vue-toasted';

document.addEventListener('DOMContentLoaded', () => {
  if(!document.querySelector('#vue-app')) return

  Vue.use(axios)
  Vue.use(ElementUI, { locale })
  Vue.use(Toasted)

  const app = new Vue({
    el: "#vue-app",
    vuetify,
    components: {
      ShiftCalendar,
      ReservationCalendar,
      ATypeCalendar,
      UserReservation,
      UserBooking,
      LocationScheduleForm
    },
  });
})
