<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 ">
        <button class="btn btn-lg w-100 btn-primary" @click="setScreen('admin')">
          <h4 class="text-white mt-3"><i class="fas fa-users"> </i> スタッフを選ぶ</h4>
          <p class="mb-2">スタッフを予約します。</p>
        </button>
      </div>

      <div class="col-12 col-md-12 col-lg-6 ">
        <button class="btn btn-lg w-100 btn-primary" @click="setScreen('schedule')">
          <h4 class="text-white mt-3"><i class="fas fa-calendar-alt"> </i> 日程を選ぶ</h4>
          <p class="mb-2">日程を予約します。</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'select-process',
  props: {
    screen: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  methods: {
    setScreen(screen){
      this.$emit('setScreen', screen)
      if(screen == 'admin'){
        this.$emit('fetchAdmins')
      } else if(screen == 'schedule'){
        this.$emit('fetchSchedules')
      }
    }
  }
}
</script>
