<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="text-center">下記の内容で予約を完了しました。</h3>
      </div>
    </div>
    <div class="row text-black">
      <div class="col-12">
        <h5><i class="fas fa-info-circle"></i> 予約内容</h5>
        <p><b>予約番号</b> {{ result.id }}</p>
        <p><b>氏名</b> {{ result.name }} 様</p>
        <p><b>電話番号</b> {{ result.tel }}</p>
        <p><b>メールアドレス</b> {{ result.email }}</p>
        <p><b>日時</b> {{ result.time }}</p>
        <p><b>店舗</b> {{ result.location }}</p>
        <p><b>担当</b> {{ result.main_admin }}</p>
        <p><b>コース</b> {{ result.courses }}</p>
        <p><b>お困りの症状など</b> {{ result.comment }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'complete',
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  methods: {
    setScreen(screen){
      this.$emit('setScreen', screen)
    }
  }
}
</script>
