<template>
  <v-row justify="center">
    <v-dialog
      v-model="displayDialog"
      @click:outside="closeDialog"
      persistent
    >
      <v-card>
        <v-card-title>
          <b>{{ page_title }}
            <span v-if="modelId != undefined"> #{{modelId}} </span>
          </b>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>

             <v-col cols="12">
                <label>店舗</label>
                <el-select v-model="reservation.location_id" @change="selectLocation">
                  <el-option
                    v-for="location in locations"
                    :key="location.name"
                    :label="location.name"
                    :value="location.id">
                  </el-option>
                </el-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label>日程</label>
                <el-date-picker
                  type="date"
                  v-model="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="日程を選択してください"
                  >
                </el-date-picker>
              </v-col>
              <v-col cols="4">
                <label>開始時間</label>
                <el-time-select
                v-model="start_at"
                :picker-options="{
                  start: '09:00',
                  step: '00:30',
                  end: '21:00'
                }"
                placeholder="開始時間を選択してください">
                </el-time-select>
              </v-col>
              <v-col cols="4">
                <label>終了時間</label>
                <el-time-select
                v-model="end_at"
                :picker-options="{
                  start: '09:00',
                  step: '00:30',
                  end: '21:00'
                }"
                placeholder="終了時間を選択してください">
                </el-time-select>
              </v-col>
            </v-row>

            <hr>

            <v-row>
              <v-col cols="4">
                <label>スタッフ</label>
                <el-autocomplete
                  class="inline-input"
                  :fetch-suggestions="adminQuerySearch"
                  placeholder="スタッフ名"
                  @select="selectAdmin"
                ></el-autocomplete>
              </v-col>

              <v-col cols="8">
                <label>選択されたスタッフ</label>
                <p v-if="!selectedAdmins.length">
                  ※ 選択されたスタッフがいません。
                </p>
                <ul class="list-group pl-0" v-else>
                  <li class="list-group-item" :key="index" v-for="(admin, index) in selectedAdmins">
                    <div class="row" v-if="admin._destroy">
                      <div class="col"><s><b>{{admin.value}}</b></s></div>
                    </div>

                    <div class="row" v-else>
                      <div class="col-sm-4 mt-1">
                        <b>{{admin.value}}</b>
                      </div>
                      <div class="col-sm-4 mt-1">
                        <el-switch
                          v-model="admin.specify"
                          active-text="指名"
                          inactive-text="非指名">
                        </el-switch>
                      </div>
                      <select
                        v-model="admin.label" class="form-control my-2 col-sm-2">
                        <option v-for="label in labels">
                          {{ label }}
                        </option>
                      </select>
                      <div class="col-sm-2 mt-0">
                        <button class="btn btn-primary btn-sm" @click="removeAdmin(admin)">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </li>

                </ul>

              </v-col>
            </v-row>

            <hr>

            <v-row>
              <v-col cols="4">
                <label>コース</label>
                <el-autocomplete
                  class="inline-input"
                  :fetch-suggestions="courseQuerySearch"
                  placeholder="コース名"
                  @select="selectCourse"
                ></el-autocomplete>
              </v-col>

              <v-col cols="8">
                <label>選択されたコース</label>
                <p v-if="!selectedCourses.length">
                  ※ 選択されたコースがありません。
                </p>

                <ul class="list-group pl-0" v-else>
                  <li class="list-group-item" :key="index" v-for="(course, index) in selectedCourses">
                    <div class="row" v-if="course._destroy">
                      <div class="col"><s><b>{{course.value}}</b></s></div>
                    </div>
                    <div class="row" v-else>
                      <div class="col-sm-10 mt-1">{{course.value}}</div>
                      <div class="col-sm-2 mt-0">
                        <button class="btn btn-primary btn-sm" @click="removeCourse(course)">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </v-col>
            </v-row>

            <hr>


            <v-row>
              <v-col cols="4">
                <label>コースで使用する設備</label>
                <el-autocomplete
                  class="inline-input"
                  :fetch-suggestions="locationEquipmentQuerySearch"
                  placeholder="設備名"
                  @select="selectLocationEquipment"
                ></el-autocomplete>
              </v-col>

              <v-col cols="8">
                <label>選択された設備</label>
                <p v-if="!selectedLocationEquipments.length">
                  ※ 選択された設備がありません。
                </p>
                <ul class="list-group pl-0" v-else>
                  <li class="list-group-item" :key="index" v-for="(locationEquipment, index) in selectedLocationEquipments">
                    <div class="row" v-if="locationEquipment._destroy">
                      <div class="col"><s><b>{{locationEquipment.value}}</b></s></div>
                    </div>
                    <div class="row" v-else>
                      <div class="col-sm-10 mt-1">{{locationEquipment.value}}</div>
                      <div class="col-sm-2 mt-0">
                        <button class="btn btn-primary btn-sm" @click="removeLocationEquipment(locationEquipment)">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </v-col>
            </v-row>

            <hr>

            <v-row if="errors.length">
              <v-col cols="12">
                <ul class="list-unstyled">
                  <li v-for="error in errors" class="text-danger alert alert-danger">{{error}}</li>
                </ul>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions>
          <a :href="`/admin/reservations/${modelId}`" target="_blank" class="btn btn-primary text-white" v-if="modelId">詳細</a>
          <v-spacer></v-spacer>



          <button
            type="button"
            class="btn btn-light"
            @click="closeDialog">
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="save">
            <span v-if="modelId == undefined"> 登録する </span>
            <span v-else> 更新する </span>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, required: true },
    selectedDate: { type: String },
    selectedHour: { type: Number },
    selectedMin: { type: String },
    modelId: { type: Number }
  },
  data() {
    return {
      page_title: "予約作成",
      date: null,
      reservation: {location_id: null, profile: {}},
      locations: [],
      courses: [],
      location_id: null,
      admins: [],
      users: [],
      selectedEquipments: [],
      labels: ["担当" , "補助者"],
      searchUserName: "",
      selectedAdmins: [],
      selectedCourses: [],
      locationEquipments: [],
      selectedLocationEquipments:[],
      start_at: null,
      end_at: null,
      errors: [],
    }
  },
  computed: {
    displayDialog: {
      get() { return this.value },
      set(value) {this.$emit('input', value)}
    },
  },
  methods: {
    selectAdmin(admin){
      if(this.selectedAdmins.some(selected => (selected.admin_id === admin.admin_id) && !selected._destroy )){
        return
      }
      this.checkAvailableSchedule(admin)
      this.selectedAdmins.push(admin)
    },
    removeAdmin(selectedAdmin){
      const index = this.selectedAdmins.findIndex((selected) => {
                      if(selected.id){
                        return (selected.admin_id === selectedAdmin.admin_id && !selected.id)
                      } else {
                        return (selected.admin_id === selectedAdmin.admin_id)
                      }
                    })
      if(selectedAdmin.id){
        this.$set(selectedAdmin, '_destroy', 1)
        return
      }

      this.selectedAdmins.splice( index, 1 )
    },
    adminQuerySearch(queryString, cb) {
      var admins = this.admins.map((admin) => {
        return {admin_id: admin.id, value: admin.name, label: "担当"}
      })
      var results = queryString ? admins.filter(this.createAdminFilter(queryString)) : admins
      cb(results)
    },
    createAdminFilter(queryString) {
      return (admin) => {
        return (admin.name.indexOf(queryString) === 0);
      };
    },

    selectCourse(course){
      if(this.selectedCourses.some(selected => selected.course_id === course.course_id && !selected._destroy )){
        return
      }
      this.selectedCourses.push(course)
    },
    removeCourse(selectedCourse){
      const index = this.selectedCourses.findIndex((selected) => {
                      if(selected.id){
                        return (selected.course_id === selectedCourse.course_id && !selected.id)
                      } else {
                        (selected.course_id === selectedCourse.course_id)
                      }
                    })

      if(selectedCourse.id){
        this.$set(selectedCourse, '_destroy', 1)
        return
      }

      this.selectedCourses.splice( index, 1 )
    },
    courseQuerySearch(queryString, cb) {
      var courses = this.courses.map((course) => {
        return {course_id: course.id, value: course.title}
      })
      var results = queryString ? courses.filter(this.createCourseFilter(queryString)) : courses
      cb(results)
    },
    createCourseFilter(queryString) {
      return (course) => {
        return (course.title.indexOf(queryString) === 0);
      };
    },
    selectLocationEquipment(locationEquipment){
      if(this.selectedLocationEquipments.some(selected => selected.id === locationEquipment.id && !selected._destroy )){
        return
      }
      this.selectedLocationEquipments.push(locationEquipment)
    },
    removeLocationEquipment(selectedLocationEquipment){
      const index = this.selectedLocationEquipments.findIndex((selected) => {
                      if(selected.id){
                        return (selected.equipment_id === selectedLocationEquipment.equipment_id && !selected.id)
                      } else {
                        (selected.equipment_id === selectedLocationEquipment.equipment_id)
                      }
                    })

      if(selectedLocationEquipment.id){
        this.$set(selectedLocationEquipment, '_destroy', 1)
        return
      }

      this.selectedLocationEquipments.splice( index, 1 )
    },
    locationEquipmentQuerySearch(queryString, cb) {
      var locationEquipments = this.locationEquipments.map((locationEquipment) => {
        return {location_equipment_id: locationEquipment.id, value: locationEquipment.display_name}
      })
      var results = queryString ? locationEquipments.filter(this.createLocationEquipmentFilter(queryString)) : locationEquipments
      cb(results)
    },
    createLocationEquipmentFilter(queryString) {
      return (locationEquipment) => {
        return (locationEquipment.display_name.indexOf(queryString) === 0);
      };
    },
    async fetchUsers() {
      const result = await this.axios.get(
          `/api/v1/admin/users?name=${this.searchUserName}`)

      const body    = await result.data
      this.users = result.data.users
    },
    async userQuerySearchAsync(queryString, cb) {
      await this.fetchUsers()
      var users = this.users.map((user) => {
        return {id: user.id, value: user.name, name: user.name, email: user.email, tel: user.tel}
      })
      let results  = queryString ? users.filter(this.createUserFilter(queryString)) : users
      cb(results)
    },
    createUserFilter(queryString) {
      return (user) => {
        return (user.value && user.value.indexOf(queryString) > -1)
      }
    },
    selectUser(user){
      this.$set(this.reservation.profile, 'name', user.name)
      this.$set(this.reservation.profile, 'tel', user.tel)
      this.$set(this.reservation.profile, 'email', user.email)
      this.$set(this.reservation.profile, 'user_id', user.id)
    },
    async initForm() {
      if(this.selectedDate){
        this.date = this.selectedDate
      }

      if (this.modelId){
        this.page_title = "予約編集"
        const result = await this.fetchEvent()
        const reservation = result.data.reservation
        this.$set(this.reservation, 'id', this.modelId)
        this.$set(this.reservation, 'location_id', reservation.location_id)
        this.$set(this.reservation, 'user_id', reservation.user_id)
        this.$set(this.reservation, 'comment', reservation.comment)
        if(reservation.reservation_profile){
          this.$set(this.reservation.profile, 'name', reservation.reservation_profile.name)
          this.$set(this.reservation.profile, 'tel', reservation.reservation_profile.tel)
          this.$set(this.reservation.profile, 'email', reservation.reservation_profile.email)
        }
        this.initDate(reservation)
        this.initAdmins(reservation)
        this.initCourses(reservation)
        this.initLocationEquipments(reservation)
      }
      else{
        this.initReservation()
        this.page_title = "予約作成"
      }
      this.fetchLocations()
      this.fetchCourses()
      this.fetchAdmins()
      this.fetchLocationEquipments()
    },
    initReservation(){
      this.$set(this.reservation, {})
      this.$set(this.reservation, 'profile', {})
      this.selectedAdmins             = []
      this.selectedCourses            = []
      this.selectedLocationEquipments = []

      if(this.selectedMin){
        var min = this.selectedMin
      } else {
        var min = "00"
      }

      if(this.selectedHour){
        const start_hour = ('00' + this.selectedHour ).slice(-2)
        const end_hour   = ('00' + (this.selectedHour + 1) ).slice(-2)
        this.start_at    = `${start_hour}:${min}`
        this.end_at      = `${end_hour}:${min}`
      } else {
        this.start_at = null
        this.end_at   = null
      }
    },
    resetReservationProfile(){
      this.$set(this.reservation, 'profile', {})
    },
    initEndAt(){
      const start_at = new Date(this.selectedDate)
      return new Date (start_at.setHours( start_at.getHours() + 9 ) )
    },
    async fetchLocations() {
      await this.axios.get('/api/v1/admin/locations').then((response) => {
        this.locations = response.data.locations
      }).catch((error) => {
      })
    },
    async fetchCourses() {
      return await this.axios.get(`/api/v1/admin/courses`, {params: {location_id: this.reservation.location_id, a_type: true}}).then((response) => {
        this.courses = response.data.courses
      }).catch((error) => {
      })
    },
    async selectLocation(){
      await this.fetchAdmins()
      await this.fetchCourses()
      await this.fetchLocationEquipments()
    },
    async fetchAdmins() {
      if(this.reservation && this.reservation.location_id){
        let location_id = this.reservation.location_id
      } else {
        let location_id = ""
      }
      await this.axios.get(`/api/v1/admin/available_admins`, {params: {location_id: this.reservation.location_id}}).then((response) => {
        this.admins = response.data.available_admins
      }).catch((error) => {
      })
    },
    async fetchLocationEquipments() {
      if(this.reservation && this.reservation.location_id){
        let location_id = this.reservation.location_id
      } else {
        let location_id = ""
      }
      await this.axios.get(`/api/v1/available_location_equipments`, {params: {location_id: this.reservation.location_id}}).then((response) => {
        this.locationEquipments = response.data.location_equipments
      }).catch((error) => {
      })
    },
    async fetchEvent() {
      return await this.axios.get(`/api/v1/admin/reservations/${this.modelId}/edit`)
    },
    initAdmins(reservation){
      this.selectedAdmins = reservation.admin_reservations.map((admin_reservation) => {
                              return {
                                name: admin_reservation.name,
                                value: admin_reservation.name,
                                admin_id: admin_reservation.admin_id,
                                id: admin_reservation.id,
                                label: admin_reservation.label,
                                specify: admin_reservation.specify
                              }
                             })
    },
    initCourses(reservation){
      this.selectedCourses = reservation.reservation_courses.map((reservation_course) => {
                              return {
                                title: reservation_course.title,
                                value: reservation_course.title,
                                id: reservation_course.id,
                                course_id: reservation_course.course_id
                              }
                             })
    },
    initLocationEquipments(reservation){
      this.selectedLocationEquipments = reservation.reservation_location_equipments.map((reservation_location_equipment) => {
                                          return {
                                            value: reservation_location_equipment.display_name,
                                            id: reservation_location_equipment.id,
                                            location_equipment_id: reservation_location_equipment.location_equipment_id,
                                            equipment_id: reservation_location_equipment.equipment_id,
                                          }
                                        })
    },
    initDate(reservation){
      let start_at  = new Date(reservation.start_at)
      let end_at    = new Date(reservation.end_at)

      let year      = start_at.getFullYear()
      let month     = ("0"+(start_at.getMonth() + 1)).slice(-2)
      let day       = ("0"+(start_at.getDate())).slice(-2)
      this.date     = `${year}-${month}-${day}`

      this.start_at = `${start_at.getHours().toString().padStart(2, '0')}:${start_at.getMinutes().toString().padStart(2, '0')}`
      end_at.setMinutes(end_at.getMinutes() + 1)
      this.end_at = `${end_at.getHours().toString().padStart(2, '0')}:${end_at.getMinutes().toString().padStart(2, '0')}`
    },
    createTime(str){
      let year  = str.substr(0, 4)
      let month = str.substr(5, 2)
      let day   = str.substr(8, 2)
      let hour  = str.substr(11, 2)
      let min   = str.substr(14, 2)
      let date  = new Date(year, month - 1, day, hour, min, 0)
      return date
    },
    startAtAttribute(){
      return this.createTime(`${this.date} ${this.start_at}`).toLocaleString('ja-JP')
    },
    endAtAttribute(){
      var end_at = this.createTime(`${this.date} ${this.end_at}`)
      end_at.setMinutes(end_at.getMinutes() - 1)
      return end_at.toLocaleString('ja-JP')
    },
    adminAttributes(){
      return this.selectedAdmins.map((selected_admin) => {
        return {
          id: selected_admin.id,
          admin_id: selected_admin.admin_id,
          label: selected_admin.label,
          specify: selected_admin.specify,
          _destroy: selected_admin._destroy,
        }
      })
    },
    courseAttributes(){
      return this.selectedCourses.map((reservation_course) => {
        return {id: reservation_course.id, course_id: reservation_course.course_id, _destroy: reservation_course._destroy}
      })
    },
    locationEquipmentAttributes(){
      return this.selectedLocationEquipments.map((reservation_location_equipment) => {
        return {id: reservation_location_equipment.id, location_equipment_id: reservation_location_equipment.location_equipment_id, _destroy: reservation_location_equipment._destroy}
      })
    },
    formattedProfile(){
      return {name: this.reservation.profile.name, email: this.reservation.profile.email, tel: this.reservation.profile.tel}
    },
    validationDateRange(){
      let start_at = this.createTime(`${this.date} ${this.start_at}`)
      let end_at   = this.createTime(`${this.date} ${this.end_at}`)

      if(start_at.toString() == "Invalid Date"){
        this.errors.push("日付が不正です")
      } else if ( end_at.toString() == "Invalid Date" ){
        this.errors.push("日付が不正です")
      } else if (start_at > end_at){
        this.errors.push("日付が不正です")
      }
    },
    validationAdmin(){
      if(!this.adminAttributes().length){
        this.errors.push("スタッフが指定されていません。")
      }
    },
    validationCourse(){
      if(!this.courseAttributes().length){
        this.errors.push("コースが指定されていません。")
      }
    },
    validationLocation(){
      if(!this.reservation.location_id){
        this.errors.push("店舗を入力してください")
      }
    },
    async checkAvailableSchedule(admin){
      // /api/v1/available_admins?location_id=1&admin_id=14&datetime=2022%2F3%2F23+09:00
      if(!this.reservation.location_id){
        return
      }
      if(!this.start_at){
        return
      }
      if(!this.date){
        return
      }

      const start_at   = this.createTime(`${this.date} ${this.start_at}`)
      const dataParams = `${start_at.getFullYear()}/${start_at.getMonth() + 1}/${start_at.getDate()} ${this.start_at}`
      const params = {location_id: this.reservation.location_id, datetime: dataParams}

      await this.axios.get(`/api/v1/admin/check_available_admins`, {params: params}).then((response) => {
        const exist = response.data.available_admins.some(a => a.id == admin.admin_id)
        if(!exist){
          alert(`${admin.value}はこの日程に診療枠がない可能性があります`)
        }
      }).catch((error) => {
      })

    },

    validation(){
      this.validationLocation()
      this.validationDateRange()
      this.validationAdmin()
      this.validationCourse()
    },
    async save() {
      this.errors = []
      let params = {
        reservation: {
          id: this.reservation.id,
          location_id: this.reservation.location_id,
          user_id: this.reservation.profile.user_id,
          comment: this.reservation.comment,
          start_at: this.startAtAttribute(),
          end_at: this.endAtAttribute(),
          admin_reservations_attributes: this.adminAttributes(),
          reservation_courses_attributes: this.courseAttributes(),
          reservation_location_equipments_attributes: this.locationEquipmentAttributes(),
          reservation_profile_attributes: this.formattedProfile()
        }
      }
      this.validation()
      if(this.errors.length){
        return
      }

      if (this.modelId) {
        await this.axios.put(`/api/v1/admin/reservations/${this.modelId}`, params, {}).then((response) => {
          this.$emit('reloadList')
          this.displayDialog = false
        }).catch((error) => {
          this.errors = error.response.data.errors
        })
      } else {
        await this.axios.post('/api/v1/admin/reservations', params, {}).then((response) => {
          this.$emit('reloadList')
          this.displayDialog = false
        }).catch((error) => {
          this.errors = error.response.data.errors
        })
      }
    },
    closeDialog() {
      this.$emit('input', false)
      this.$emit('close-dialog')
    },
  },
  watch: {
    value() {
      if(this.value) this.initForm()
    }
  }
}
</script>
