export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    async fetchLocations() {
      const response = await this.axios.get("/api/v1/booking/locations");
      this.locations = response.data;
    },
    async fetchSchedules() {
      this.loading = true;
      // http://localhost:3000/api/v1/available_schedules?location_id=1&date=2024-05-08T15:03:52.417Z
      const params = {
        location_id: this.reservation.location_id,
        admin_id: this.reservation.admin_id,
        supporter_admin_ids: this.reservation.supporter_admin_ids,
        start_date: this.start_date,
      };
      try {
        const response = await this.axios.get("/api/v1/booking/schedules", {
          params,
        });
        this.schedules = response.data;
        this.loading = false;
      } catch (e) {
        if (e.response.status === 500) {
          // console.log('500 error')
          this.loading = false;
        }
      }
    },
    async fetchAdmins() {
      this.loading = true;
      this.admins = [];
      const params = {
        location_id: this.reservation.location_id,
        start_time: this.reservation.start_time,
      };
      try {
        const response = await this.axios.get("/api/v1/booking/admins", {
          params: params,
        });
        this.admins = response.data;
      } catch (e) {
        if (e.response.status === 500) {
          // console.log('500 error')
        }
      }
      this.loading = false;
    },
    async fetchCourses() {
      this.loading = true;
      const params = {
        admin_id: this.reservation.admin_id,
      };
      const response = await this.axios.get("/api/v1/booking/courses", {
        params: params,
      });
      this.courses = response.data;
      this.loading = false;
    },
    async checkAvailability() {
      const params = {
        location_id: this.reservation.location_id,
        admin_id: this.reservation.admin_id,
        start_time: this.reservation.start_time,
        end_time: this.reservation.end_time,
        course_ids: this.reservation.course_ids,
      };
      try {
        const response = await this.axios.get("/api/v1/booking/availability", {
          params: params,
        });
        return response.data;
      } catch (e) {
        if (e.response.status === 500) {
          // console.log('500 error')
        }
      }
    },
    async fetchUser() {
      try {
        const response = await this.axios.get("/api/v1/current_user", {});
        this.$set(this.reservation, "profile", response.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
