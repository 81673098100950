<template>
  <v-row justify="center">
    <v-dialog
      v-model="displayDialog"
      @click:outside="closeDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <b>{{ page_title }}</b>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <DatetimePicker v-model="start_at" :timePickerProps="startTimeOptions" label="開始" />
                <p class="error_start_at v-application error--text"></p>
              </v-col>
              <v-col cols="6">
                <DatetimePicker v-model="end_at" :timePickerProps="endTimeOptions" label="終了" />
                <p class="error_end_at v-application error--text"></p>
              </v-col>
              <v-col cols="12">
                <VSelect label="店舗選択" :items="locations" item-text="name" item-value="id" v-model="location_id"/>
                <p class="error_location v-application error--text"></p>
              </v-col>
              <v-col cols="12">
                <VSelect label="スタッフ選択" :items="admins" item-text="name" item-value="id" v-model="admin_id"/>
                <p class="error_admin v-application error--text"></p>
              </v-col>
              <v-col cols="12">
                <VTextarea label="備考" v-model="description"/>
                <p class="error_description v-application error--text"></p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>

          <v-spacer>
            <button type="button" class="btn btn-primary" @click="goToCopy" v-if="modelId">コピー</button>
          </v-spacer>

          <v-spacer></v-spacer>
          <button
            type="button"
            class="btn btn-light"
            @click="displayDialog = false"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="save"
          >
            <span v-if="modelId == undefined"> 登録する </span>
            <span v-else> 更新する </span>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DatetimePicker from '../../vuetify_parts/DateTimePicker.vue'
export default {
  props: {
    value: { type: Boolean, required: true },
    selectedDate: { type: String },
    modelId: { type: Number }
  },
  components: {
    DatetimePicker
  },
  data() {
    return {
      page_title: "診療枠作成",
      locations: [],
      location_id: null,
      admins: [],
      admin_id: null,
      start_at: null,
      end_at: null,
      description: '',
    }
  },
  computed: {
    displayDialog: {
      get() { return this.value },
      set(value) {this.$emit('input', value)}
    },
    startTimeOptions() {
      return {
        'allowed-minutes': [0, 15, 30, 45, 60],
      }
    },
    endTimeOptions() {
      return {
        'allowed-minutes': [0, 15, 30, 45, 60],
      }
    },
  },
  methods: {
    initForm() {
      this.initLocations();
      this.initAdmins();
      this.start_at = new Date(this.selectedDate)
      this.admin_id = null
      this.location_id = null
      this.end_at = this.initEndAt()
      this.description = ""
      this.clearOldErrors()
      if (this.modelId){
        this.page_title = "診療枠編集"
        this.fetchEvent()
      }
      else{
        this.page_title = "診療枠作成"
      }
    },
    initEndAt(){
      const start_at = new Date(this.selectedDate)
      return new Date (start_at.setHours( start_at.getHours() + 9 ) )
    },
    async initLocations() {
      await this.axios.get('/api/v1/admin/locations').then((response) => {
        this.locations = response.data.locations
      }).catch((error) => {
      })
    },
    async initAdmins() {
      await this.axios.get('/api/v1/admin/admins').then((response) => {
        this.admins = response.data.admins
      }).catch((error) => {
      })
    },
    async fetchEvent() {
      await this.axios.get(`/api/v1/admin/shift_tables/${this.modelId}/edit`).then((response) => {
        const event = response.data.shift_table;
        this.admin_id = event.admin.id
        this.location_id = event.location.id
        this.start_at = new Date(event.start_at)
        this.end_at = new Date(event.end_at)
        this.description = event.description
      }).catch((error) => {
      })
    },
    async save() {
      let params = {
        shift_table: {
          admin_id: this.admin_id,
          location_id: this.location_id,
          start_at: this.start_at,
          end_at: this.end_at,
          description: this.description
        }
      }

      if (this.modelId) {
        await this.axios.put(`/api/v1/admin/shift_tables/${this.modelId}`, params, {}).then((response) => {
          this.$emit('reloadList')
          this.displayDialog = false
          this.clearOldErrors()
        }).catch((error) => {
          this.renderErrors(error.response.data.errors)
        })
      } else {
        await this.axios.post('/api/v1/admin/shift_tables', params, {}).then((response) => {
          this.$emit('reloadList')
          this.displayDialog = false
          this.clearOldErrors()
        }).catch((error) => {
          this.renderErrors(error.response.data.errors)
        })
      }
    },
    clearOldErrors() {
      $(".v-application.error--text").text('');
    },
    renderErrors(errors) {
      this.clearOldErrors()
      $.each(errors, function(key, value){
        $(".error_" + key).html(value.join("<br>"));
      });
    },
    closeDialog() {
      this.$emit('input', false);
    },
    goToCopy() {
      window.location = `/admin/admins/${this.admin_id}/shift_tables/new?copy_id=${this.modelId}`
    }
  },
  watch: {
    value() {
      if(this.value) this.initForm()
    }
  }
}
</script>

<style>

</style>
