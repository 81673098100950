<template>
  <div>
    <div class="row">
      <div class="col py-0">
        <div class="page-title-box">
          <h4 class="page-title text-black">予約する</h4>
        </div>
      </div>
    </div>

    <step></step>

    <div class="row">
      <div class="col-3 text-center">
        <button type="button" :class="getClassNavi('location')" @click="openToLocation">
          1.店舗<span class="d-none d-md-inline-block">を選ぶ</span>
        </button>
      </div>

      <div class="col-3 text-center">
        <button type="button" :class="getClassNavi('admin_or_date')" @click="openAdminList"
                :disabled="!reservation.location_id">
          2.<span class="d-none d-md-inline-block">スタッフ/</span>日程<span class="d-none d-md-inline-block">を選ぶ</span>
        </button>
      </div>

      <div class="col-3 text-center">
        <button type="button" :class="getClassNavi('course')" @click="openCourseList"
                :disabled="(!this.reservation.admin_id && !this.reservation.no_admin) || !this.reservation.start_time">
          3.コース<span class="d-none d-md-inline-block">を選ぶ</span>
        </button>
      </div>

      <div class="col-3 text-center">
        <button type="button" :class="getClassNavi('profile')" @click="openProfileForm" :disabled="!canOpenProfileForm()">
          4.予約者<span class="d-none d-md-inline-block">情報</span>
        </button>
      </div>

      <div class="col-12" v-if="reservation.location_id">
        <button
          class="btn btn-light" @click="backTask()" >
          <i class="fas fa-chevron-left"></i>
          戻る
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-9">
        <div class="card border-green">
          <div class="card-body">

            <div class="row" v-if="!reservation.location_id">
              <div class="col-12">
                <h5 class="text-black"><i class="fas fa-map-marked-alt"></i> 店舗を選択する</h5>

                <div v-if="!availableLocations.length">
                  <div class="alert alert-danger">
                    現在利用可能な店舗はありません。条件を変えて実行して下さい。
                  </div>

                  <a class="btn btn-primary btn-lg w-100" href="/">
                    戻る
                  </a>
                </div>

                <div v-else>
                  <div v-if="!reservation.location_id">
                    <div class="row">
                      <div class="col-12" v-for="location in availableLocations" :key="location.id">
                        <div class="card">
                          <div class="card-body">
                            <div class="media">
                              <img src="/images/logo.png" class="avatar-md rounded-circle mr-3 align-self-center" alt="ロゴ">
                              <div class="media-body overflow-hidden">

                                <h3 class="font-16 mt-0 mb-1 text-black">
                                  {{location.name}}
                                  <span class="badge badge-info float-right" v-if="location.address">
                                    <a :href="'https://www.google.com/maps/search/?api=1&query=' + location.address" target="_blank" class="text-white">
                                      <i class="fas fa-external-link-alt"></i> <b>地図を見る</b>
                                    </a>
                                  </span>
                                </h3>
                                <p class="mb-0 pb-0 font-15 text-black pl-1" v-if="location.address">
                                  <i class="fas fa-map-marker-alt"></i>
                                  {{location.address}}
                                </p>

                                <p class="mb-0 pb-0 font-15 pl-1" v-if="location.tel">
                                  <a :href="'tel:' + location.tel" class="text-black">
                                    <i class="fas fa-phone"></i>
                                    {{location.tel}}
                                  </a>
                                </p>

                                <p class="mb-0 pb-0 font-15 pl-1 text-black" v-if="location.operation_time">
                                  <i class="far fa-clock"></i> {{location.operation_time}}
                                </p>

                                <p class="mb-0 pb-0 font-15 pl-1" v-if="location.url">
                                  <a :href="'tel:' + location.url" class="text-black">
                                    <i class="fab fa-safari"></i>
                                    {{location.url}}
                                  </a>
                                </p>

                                <div class="row mt-2">
                                  <div class="col-12 col-lg-4 offset-lg-8 col-md-6 offset-md-6">
                                    <button class="btn btn-primary btn-block btn-rounded" @click="setLocation(location.id)">
                                      <i class="fa fa-check"></i>
                                      この店舗を選ぶ
                                    </button>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="row" v-if="canShowProfileForm">
              <div class="col-12">
                <div class="p-3 bg-green">
                  <h5 class="text-black"><i class="fas fa-info-circle"></i> 予約者情報</h5>

                  <div class="form-row">
                    <div class="col-12">
                      <label for="name">
                        <span class="text-danger">※</span>
                        お名前
                      </label>
                      <input type="text" class="form-control" id="name" placeholder="山田 太郎" v-model="reservation.user.name">
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-12">
                      <label for="tel">
                        <span class="text-danger">※</span>
                        電話番号
                      </label>
                      <input type="tel" class="form-control" id="tel" placeholder="09000000000" pattern="^[0-9]+$" maxlength="11" v-model="reservation.user.tel">
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-12">
                      <label for="email"><span class="text-danger">※</span>メールアドレス</label>
                    <input type="email" class="form-control" id="email" placeholder="info@example.com" v-model="reservation.user.email">
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-12">
                      <label for="comment">現在お困りの症状など(250文字以内)</label>
                      <textarea style="height: 100px;" class="form-control" id="comment" placeholder="" v-model="reservation.comment" rows="10"></textarea>
                    </div>
                  </div>


                  <hr>
<!--
                  <div class="form-row">
                    <div class="col-sm-4 col-12">
                      <label for="zipcode">郵便番号</label>
                      <input @blur="fetchAddress" type="zipcode" class="form-control" id="zipcode" maxlength="7" placeholder="0000000" v-model="reservation.user.zipcode">
                    </div>
                    <div class="col-sm-8 col-12">
                      <label for="prefecture">都道府県</label>
                      <select class="form-control w-50" id="prefecture" v-model="reservation.user.prefecture">
                      <option
                        v-for="prefecture in prefectures"
                        :value="prefecture.id"
                        :key="prefecture.id">
                        {{ prefecture.name }}
                      </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-sm-6 col-12">
                      <label for="city">市区町村</label>
                      <input type="city" class="form-control" id="city" v-model="reservation.user.city">
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-sm-6 col-12">
                      <label for="block">以降の住所</label>
                      <input type="block" class="form-control" id="block" v-model="reservation.user.block">
                    </div>

                    <div class="col-sm-6 col-12">
                      <label for="building">建物など</label>
                      <input type="building" class="form-control" id="building" v-model="reservation.user.building">
                    </div>
                  </div>
-->
                  <button
                    class="mt-3 btn btn-primary btn-lg w-100 d-none d-md-inline-block"
                    type="button"
                    v-bind:class="{'ripple-primary': valid() }"
                    :disabled="!valid()"
                    @click="createReservation"><b>この内容で予約する</b>
                  </button>


                </div>

              </div>
            </div>


            <div v-if="reservation.location_id">

              <div class="row" v-if="!proceedBy">
                <div class="col-12 col-md-12 col-lg-6 ">
                  <button class="btn btn-lg w-100 btn-primary" @click="openAdminList">
                    <h4 class="text-white mt-3"><i class="fas fa-users"> </i> スタッフから選ぶ</h4>
                    <p class="mb-2">スタッフから予約します。</p>
                  </button>
                </div>

                <div class="col-12 col-md-12 col-lg-6 ">
                  <button class="btn btn-lg w-100 btn-primary" @click="openDateList">
                    <h4 class="text-white mt-3"><i class="fas fa-calendar-alt"> </i> 日程から選ぶ</h4>
                    <p class="mb-2">日程から予約します。</p>
                  </button>
                </div>
              </div>

              <div class="row" v-if="canShowAdminSelect()">
                <div class="col-12">
                  <h5 class="text-black"><i class="fa fa-users"></i> スタッフを選ぶ</h5>
                  <!-- <p class="text-info">指名選択以外の場合は補助者がサポートさせて頂く場合があります。</p> -->

                  <div v-if="availableAdmins.length">

                    <div class="row">
                      <div class="col-12" v-for="admin in availableAdmins" :key="admin.id">
                        <div class="card">
                          <div class="card-body">
                            <div class="media">
                              <div class="row">
                                <div class="col-12 col-sm-4">
                                  <img :src="admin.thumbnail" class="img-fluid" alt="プロフィール画像">
                                </div>
                                <div class="col-12 col-sm-8">
                                  <div class="media-body">

                                    <h3 class=" mt-0 mb-1 text-black">
                                      {{admin.position}} {{admin.display_name}}
                                    </h3>

                                    <div class="bg-green my-3 p-3 text-black" v-if="admin.license">
                                      <p class="mb-0 pb-0 font-15 pl-1">
                                        <b>資格:</b> {{admin.license}}
                                      </p>
                                    </div>

                                    <div style="white-space: pre-wrap; line-height: 1;" class="mb-0 pb-0 font-15 pl-1 text-black" v-if="admin.introduction" v-text="admin.introduction">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row mt-3">
                            <!--
                              <div class="col-sm-6">
                                <button v-if="canShowWithAbility(admin, 'specified')"
                                  @click="setAdmin(admin.id, true)"
                                  v-tooltip.top="'指名選択すると他の補助者などがサポートさせて頂くことはありません。'"
                                  class="btn btn-info btn-block btn-rounded">
                                  指名選択する
                                </button>
                              </div>
                            -->
                              <div class="col-sm-12">
                                <button
                                  class="btn btn-primary btn-block btn-rounded"
                                  @click="setAdmin(admin.id, false)">
                                  <i class="fa fa-check"></i>
                                  このスタッフを選択する
                                </button>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <button @click="setNoAdmin()" class="btn btn-primary btn-block btn-rounded btn-xl">指名なしで進む</button>
                        <p class="mt-3 font-16  text-black text-center">特に希望のスタッフがいない場合はこちらからお進み下さい。</p>
                      </div>
                    </div>

                  </div>


                  <div v-if="!availableAdmins.length">
                    <div class="alert alert-danger">
                      現在利用可能なスタッフの空きがありません。条件を変えて実行して下さい。
                    </div>

                    <button
                      class="btn btn-primary btn-lg w-100"
                      type="button"
                      @click="backTask()"> 戻る
                    </button>
                  </div>

                </div>
              </div>

              <div class="row" v-if="canShowDateSelect()">
                <div class="col-12" v-if="!this.availableTimes.length">
                  <div class="alert alert-danger">
                    現在利用可能な時間がありません。条件を変えて実行して下さい。
                  </div>
                  <button
                    class="btn btn-primary btn-lg w-100"
                    type="button"
                    @click="backTask()"> 戻る
                  </button>
                </div>

                <template v-if="this.availableTimes.length">
                  <div class="col-sm-12">
                    <h5>
                      <span class="text-black"><i class="fa fa-calendar-alt"></i> 日程を選ぶ</span>
                    </h5>


                    <div class="d-sm-flex justify-content-between">
                      <div>
                        <h5 class="text-black">
                        {{ availableSchedules.date[0].year }}年{{ availableSchedules.date[0].month }}月</h5>
                      </div>
                      <div>
                        <button class="btn btn-light" @click="setDateSearch(-30)">
                          <i class="fas fa-angle-left"></i>
                          前月
                        </button>

                        <button class="btn btn-light" @click="setDateSearch(-7)">
                          <i class="fas fa-angle-left"></i>
                          前週
                        </button>

                        <button class="btn btn-primary" @click="setDateSearch(0)">今日</button>

                        <button class="btn btn-primary" @click="setDateSearch(7)">
                          次週 <i class="fas fa-angle-right"></i>
                        </button>

                        <button class="btn btn-primary" @click="setDateSearch(30)">
                          次月 <i class="fas fa-angle-right"></i>
                        </button>
                      </div>
                    </div>


                    <div class="table-responsive">
                      <table class="table table-sm table-bordered schedule-table mt-2">
                        <thead style="position: sticky;top: 0">
                          <tr>
                            <th></th>
                            <td class="align-middle" :class="getClassDate(date)" v-for="( date, index ) in availableSchedules.date" :key="index">
                              <span class="day">{{date["day"]}}</span>
                              <span class="wday">({{ getWday(date) }})</span>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(time, index) in this.availableTimes" :key="index" class="text-black">
                            <th class="align-middle time">{{time}}</th>
                            <td v-for="schedule in filteredAvailableSchedule( time )" class="align-middle">
                              <span class="not-available" v-if="!schedule.availability">
                                <span class="d-inline-block d-md-none mb-2">x</span>
                                <span class="d-none d-md-inline-block">{{schedule.label}}</span>
                              </span>
                              <button v-else
                                class="date-button btn btn-light mb-1"
                                @click="setDate(`${schedule.year}/${schedule.date} ${time}`)">
                                <i class="far fa-circle mb-1"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
              </div>

              <div class="row" v-if="showCourseSelect()">
                <div class="col-12">
                  <h5 class="text-black">コースを選ぶ</h5>

                  <div class="row" v-if="availableCourses.length">
                    <div class="col-12 col-sm-12" v-for="(course, index) in availableCourses" :key="course.id">

                      <div class="card">
                        <div class="card-body">

                          <div class="media">
                            <img src="/images/logo.png" class="avatar-md rounded-circle mr-3 align-self-center" alt="ロゴ">
                            <div class="media-body overflow-hidden">

                              <h3 class="font-15 mt-0 mb-1 text-black">
                                {{course.title}}
                                <small class="badge badge-info float-right" v-if="course.occupation">
                                  <b>{{ course.occupation / 60 }}分</b>
                                </small>
                              </h3>
                              <p class="mb-0 pb-0 font-13 text-black pl-1" v-if="course.comment">
                                <i class="far fa-comment"></i>
                                {{course.comment}}
                              </p>

                              <h3 class="mt-3 mb-1 text-danger text-right">
                                {{course.price}}円
                              </h3>

                              <hr>

                              <button
                                v-if="course.checked"
                                class="btn btn-light btn-rounded float-right"
                                @click="toggleCourse(course.id, course.occupation / 60)">
                                <i class="fa fa-check"></i> コースを外す
                              </button>

                              <button
                                v-if="!course.checked"
                                class="btn btn-primary btn-rounded float-right" @click="toggleCourse(course.id, course.occupation / 60)">
                                <i class="fa fa-check"></i> このコースを選択する
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="!availableCourses.length">
                    <div class="alert alert-danger">
                      ご指定の条件で現在利用可能なコースはありません。条件を変えて実行して下さい。
                    </div>

                    <button
                      class="btn btn-primary btn-lg w-100"
                      type="button"
                      @click="backTask()"> 戻る
                    </button>
                  </div>

                  <button
                    type="button"
                    class="btn btn-primary btn-lg w-100 ripple-primary"　
                    @click="openProfileForm()"
                    v-if="canOpenProfileForm()"
                  >予約者情報を入力する
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="new-reservation-sidebar" class="col-12 col-md-3">
        <div class="card border-green">
          <div class="card-body">
            <div class="row">
              <div class="col-12">

                <div class="bg-light text-black p-2">
                  <h5 class="text-black text-center">- 現在の選択内容 -</h5>
                  <hr>
                  <p class="mb-0"><b>店舗:</b> {{selectedLocation()}}</p>
                  <p class="mb-0"><b>スタッフ:</b> {{selectedAdmin()}}
                    <span class="badge badge-info" v-if="reservation.specify">{{ reservation.specify ? "指名" : "" }}</span>
                    <span v-if="reservation.no_admin">指名なし</span>
                  </p>

                  <p class="mb-0">
                    <span><b>日程:</b> {{reservation.start_time}}</span><span v-if="reservation.end_time">〜{{reservation.end_time.slice( -5 ) }}</span>
                  </p>
                  <p class="mb-0">
                    <b>コース:</b> {{selectedCourseTitles()}}
                  </p>
                  <hr>

                </div>

                <button
                  class="mt-3 btn btn-primary btn-lg w-100"
                  type="button"
                  v-bind:class="{'ripple-primary': valid() }"
                  :disabled="!valid()"
                  @click="createReservation"><b>この内容で予約する</b>
                </button>

                <button
                  class="mt-3 btn btn-light w-100"
                  type="button"
                  @click="reset()">リセットする
                </button>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <vue-element-loading :active="loading" :is-full-screen="true"></vue-element-loading>
  </div>
</template>

<script>
import Vue from 'vue'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)
import { format } from "date-fns"
import VueElementLoading from 'vue-element-loading'
const jsonpAdapter = require('axios-jsonp')

export default {
  data() {
    return {
      reservation: {
        location_id: null,
        admin_id: null,
        no_admin: false,
        start_time: null,
        end_time: null,
        specify: false,
        courses: [],
        user: {}
      },
      redirect_path: false,
      loading: true,
      dateSearch: new Date(),
      proceedBy: null,
      canShowProfileForm: false,
      availableAdmins: [],
      availableLocations: [],
      availableCourses: [],
      availableSchedules: [],
      availableTimes: [],
      prefectures: [],
      selectTab: "location",
    }
  },
  components: {VueElementLoading},
  methods: {
    filteredAvailableSchedule(targetf){
      return this.availableSchedules.available_schedules.map((schedule) => {
        return schedule.times.find((time) => time.time === targetf)
      })
    },
    fetchAddress(){
      const reg = /^\d{7}$/
      if (!reg.test(this.reservation.user.zipcode)) return
      this.axios.get(`https://api.zipaddress.net/?zipcode=${this.reservation.user.zipcode}`, {adapter: jsonpAdapter}).then(response => {
        const prefecture = this.findPrefectureByName(response.data.pref)
        this.$set(this.reservation.user, 'prefecture', prefecture["id"])
        this.$set(this.reservation.user, 'city', response.data.city)
        this.$set(this.reservation.user, 'block', response.data.town)
      })

    },
    findPrefectureByName(prefecture_name){
      return this.prefectures.find((p) => p.name === prefecture_name);
    },
    openLocation(){
      this.loading = true
      setTimeout(() => {
        this.loading = false

        // if(this.reservation.user.location_id){
        //   this.fetchData()
        // }
      }, 500)
    },
    profileValidation(){
      let errors = []

      if(!this.reservation.user.name){
        errors.push("お名前を入力して下さい。")
      }

      if(!this.reservation.user.tel){
        errors.push("電話番号を入力して下さい。")
      }

      if(!this.reservation.user.email){
        errors.push("メールアドレスを入力して下さい。")
      }

      if(this.reservation.comment && this.reservation.comment.length > 250){
        errors.push("お困りの症状などは250文字以内で入力してください。")
      }


      const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!emailReg.test(this.reservation.user.email)){
        errors.push("メールアドレスのフォーマットが不正です。")
      }

      errors.forEach((v) => {
        this.$toasted.show(v, {
           theme: "bubble",
           position: "top-center",
           duration : 5000
        })
      })

      if(errors.length){
        return false
      } else {
        return true
      }

    },
    setNoAdmin(){
      this.reservation.no_admin = true
      this.fetchSchedule()
    },
    canShowAdminSelect(){

      if(!this.proceedBy){
        return false
      }

      if(this.reservation.admin_id || this.reservation.no_admin){
        return false
      }

      if(this.proceedBy === "admin"){
        return true
      }

      if(this.showCourseSelect()){
        return false
      }

      if(this.reservation.start_time){
        this.selectTab = "admin_or_date"
        return true
      }

      return false
    },
    canShowWithAbility(admin, ability) {
      return admin[`can_${ability}?`]
    },
    canShowDateSelect(){

      if(!this.proceedBy){
        return false
      }

      if(this.reservation.start_time){
        return false
      }

      if(this.proceedBy === "datetime"){
        return true
      }

      if(this.showCourseSelect()){
        return false
      }

      if(this.reservation.admin_id || this.reservation.no_admin){
        this.selectTab = "admin_or_date"
        return true
      }

      return false
    },
    showCourseSelect(){
      if (this.canShowProfileForm) {
        return false
      }

      if((this.reservation.admin_id || this.reservation.no_admin) && this.reservation.start_time){
        this.selectTab = "course"
        return true
      } else {
        return false
      }
    },
    setAdmin(admin_id, specify){
      this.$set(this.reservation, 'admin_id', admin_id)
      this.$set(this.reservation, 'specify',  specify)
      this.fetchSchedule()
      this.fetchCourses()
    },
    setDate(datetime){
      this.$set(this.reservation, 'start_time', datetime)
      this.fetchAdmins()
      this.fetchCourses()
    },
    setDateSearch(intervalTime) {
      if (intervalTime == 0) {
        this.dateSearch = new Date();
      } else {
        this.dateSearch = new Date(this.dateSearch.getFullYear(), this.dateSearch.getMonth(),
          this.dateSearch.getDate() + intervalTime);
      }
      this.fetchSchedule()
    },
    setLocation(location_id) {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.$set(this.reservation, 'location_id', location_id)
        this.init()
        this.fetchAdmins()
        // this.fetchData()
      }, 500)
    },
    selectedLocation(){
      if(!this.reservation.location_id){
        return
      }
      let selectedLocation = this.availableLocations.find(o => o.id === this.reservation.location_id)
      if (selectedLocation) {
        return selectedLocation.name
      } else {
        return ""
      }
    },
    selectedAdmin(){
      if(!this.reservation.admin_id){
        return
      }
      const admin = this.availableAdmins.find(o => o.id === this.reservation.admin_id)
      if(admin){
        return admin.display_name
      } else {
        return ""
      }
    },
    selectedCourseTitles(){
      if(!this.reservation.courses.length){
        return
      }

      const courses = this.selectedCourses().map( course => {
        return course.title
      })

      return courses.join(",")
    },
    selectedCourses(){
      if(!this.reservation.courses.length){
        return
      }
      const courses = this.reservation.courses.map( course => {
        return this.availableCourses.find(o => o.id === course.id)
      })

      return courses
    },
    selectedCoursesPrice(){
      if(!this.reservation.courses.length){
        return
      }
      let total = this.selectedCourses().reduce((sum, course) => {
        return sum + course.price;
      }, 0);
      return total
    },
    init(){
      this.dateSearch = new Date()
      this.proceedBy = null
      this.$set(this.reservation, 'admin_id',    null)
      this.$set(this.reservation, 'no_admin',    false)
      this.$set(this.reservation, 'specify',     false)
      this.$set(this.reservation, 'start_time',    null)
      this.$set(this.reservation, 'end_time',    null)
      this.$set(this.reservation, 'courses', [])
    },
    reset(){
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.$set(this.reservation, 'location_id', null)
        this.canShowProfileForm = false
        this.init()
      }, 500)
    },
    toggleCourse(id, occupation){
      let existCourse = this.reservation.courses.find(course => course.id === id)
      if (existCourse) {
        this.reservation.courses.splice(this.reservation.courses.indexOf(existCourse), 1)
        this.setEndTime()
        this.setCourseChecked(id, false)
      } else {
        this.pushCourse(id, occupation)
      }
    },
    setEndTime() {
      this.$set(this.reservation, 'end_time', this.getEndTime())
    },
    totalMinuteCourses(){
      let occupationArrs = this.reservation.courses.map(course => course.occupation)
      return occupationArrs.reduce((total, minute) => total + minute, 0)
    },
    getEndTime(totalMinuteCourses = null){
      if(!totalMinuteCourses){
        totalMinuteCourses = this.totalMinuteCourses()
      }
      let startTime          = new Date(this.reservation.start_time)
      let endTime            = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), startTime.getHours(), startTime.getMinutes() + totalMinuteCourses - 1)
      let year               = endTime.getFullYear()
      let month              = ('0' + (endTime.getMonth() + 1)).slice(-2)
      let date               = ('0' + endTime.getDate()).slice(-2)
      let hours              = ('0' + endTime.getHours()).slice(-2)
      let minutes            = ('0' + endTime.getMinutes()).slice(-2)
      return `${year}/${month}/${date} ${hours}:${minutes}`
    },
    setCourseChecked(id, checked) {
      let courseAdded = this.availableCourses.find(course => course.id === id)
      let indexCourseAdded = this.availableCourses.indexOf(courseAdded)
      this.availableCourses[indexCourseAdded] = {...this.availableCourses[indexCourseAdded], checked}
    },
    async fetchLocations() {
      await this.axios.get('/api/v1/available_locations').then((response) => {
        this.availableLocations = response.data.available_locations
      }).catch((error) => {
      })
    },
    async fetchUser() {
      await this.axios.get('/api/v1/current_user', {}).then((response) => {
        this.$set(this.reservation, 'user', response.data)
        this.redirect_path = "/user/reservations"
      }).catch((error) => {
      })
    },
    async fetchAdmin() {
      await this.axios.get('/api/v1/current_admin', {}).then((response) => {
        this.redirect_path = "/admin/reservations"
      }).catch((error) => {
      })
    },
    async fetchPrefectures() {
      await this.axios.get('/api/v1/prefectures', {}).then((response) => {
        this.$set(this, 'prefectures', response.data)
      }).catch((error) => {
      })
    },
    async fetchAdmins() {
      let params = {
        location_id: this.reservation.location_id,
        datetime: this.reservation.start_time
      }

      await this.axios.get('/api/v1/available_admins', {params}).then((response) => {
        this.availableAdmins = response.data.available_admins
      }).catch((error) => {
      })
    },
    async fetchCourses() {
      let params = {
        location_id: this.reservation.location_id,
        admin_id:    this.reservation.admin_id,
        datetime:    this.reservation.start_time
      }

      await this.axios.get('/api/v1/available_courses', {params}).then((response) => {
        this.availableCourses = response.data.available_courses
      }).catch((error) => {
      })
    },
    async fetchSchedule() {
      this.loading = true
      let params = {
        location_id: this.reservation.location_id,
        admin_id: this.reservation.admin_id,
        date: this.dateSearch
      }

      await this.axios.get('/api/v1/available_schedules', {params}).then((response) => {
        this.availableSchedules = response.data.available_schedules
        this.availableTimes     = response.data.available_schedules.time
        this.loading = false
      }).catch((error) => {
      })
    },
    fetchData(){
      if (!this.prefectures.length) this.fetchPrefectures()
      if (!this.reservation.admin_id) this.fetchAdmins()
      if (!this.reservation.location_id) this.fetchLocations()
      if (!this.reservation.courses.length) this.fetchCourses()
      if (!this.reservation.start_time) this.fetchSchedule()
    },
    valid(){
      if(!(this.reservation.admin_id || this.reservation.no_admin)){
        return false
      } else if(!this.reservation.start_time){
        return false
      } else if(!this.reservation.location_id){
        return false
      } else if(!this.reservation.courses.length){
        return false
      } else if (!this.reservation.user.name || !this.reservation.user.tel || !this.canShowProfileForm) {
        return false
      } else {
        return true
      }
    },
    async createReservation() {
      if(!this.valid() || !this.profileValidation()){
        return false
      }
      let course_params = this.reservation.courses.map(course => {
        return {course_id: course.id};
      })
      let params = {
        reservation: {
          location_id: this.reservation.location_id,
          reservation_courses_attributes: course_params,
          start_at: this.reservation.start_time,
          end_at: this.reservation.end_time,
          comment: this.reservation.comment
        }
      }
      if (this.reservation.admin_id) {
        params["reservation"]["admin_reservations_attributes"] = [{admin_id: this.reservation.admin_id,
          specify: this.reservation.specify}]
      }
      if (Object.keys(this.reservation.user).length) {
        params["reservation"]["reservation_profile_attributes"] =
            {
              zipcode: this.reservation.user.zipcode,
              prefecture: this.reservation.user.prefecture,
              city: this.reservation.user.city,
              block: this.reservation.user.block,
              building: this.reservation.user.building,
              tel: this.reservation.user.tel,
              email: this.reservation.user.email,
              name: this.reservation.user.name
            }
      }

      await this.axios.post('/api/v1/reservations', params).then((response) => {
        this.loading = true
        setTimeout(() => {
          this.$toasted.show("予約を完了しました！", {
            theme: "bubble",
            type: "success",
            position: "top-center",
            duration: 2000
          })
        }, 200)

        setTimeout(() => {
          window.location = this.redirect_path ? this.redirect_path : "/"
        }, 2000)

      }).catch((error) => {
        let errors = []

        Object.entries(error.response.data.errors).forEach(([key, e]) => errors.push(e))
        errors = [...new Set(errors.flat())]
        errors.forEach((e) => {
          this.$toasted.show(e, {
            theme: "bubble",
            position: "top-center",
            duration: 3000
          })
        })
      })
    },
    openToLocation() {
      if (this.selectTab == "location") return;

      this.reset()
      this.init()
      this.selectTab = "location"
    },
    openAdminList() {
      if (this.selectTab == "admin_or_date") return;

      if (this.reservation.admin_id || this.reservation.no_admin) {
        this.canShowProfileForm = false
        this.init()
        this.fetchSchedule()

      }
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.proceedBy = "admin"
        this.selectTab = "admin_or_date"
      }, 500)
    },
    openDateList() {
      if (this.selectTab == "admin") return;
      this.fetchSchedule()
      if (this.reservation.start_time) {
        this.canShowProfileForm = false
        this.init()
      }
      this.proceedBy = "datetime"
      this.selectTab = "admin_or_date"
    },
    openCourseList() {
      if (this.selectTab == "course") return;

      this.$set(this.reservation, 'end_time', null)
      this.$set(this.reservation, 'courses', [])
      this.fetchData()
      this.selectTab = "course"
      this.canShowProfileForm = false
    },
    openProfileForm() {

      if (this.selectTab == "profile") return;

      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.canShowProfileForm = true
        this.selectTab = "profile"
        this.fetchUser()

      }, 500)

    },
    async pushCourse(id, occupation) {
      let course_ids = this.reservation.courses.map(course => {
                              return course.id
                            })
      course_ids.push(id)
      let params = {
        location_id: this.reservation.location_id,
        start_time:  this.reservation.start_time,
        end_time:    this.getEndTime(this.totalMinuteCourses() + occupation),
        course_ids:  course_ids,
        admin_id:  this.reservation.admin_id
      }

      await this.axios.get('/api/v1/check_courses', {params}).then((response) => {
        if(!response.data["result"]){
          alert(response.data["text"])
        } else {
          this.reservation.courses.push({id, occupation})
          this.setCourseChecked(id, true)
          this.setEndTime()
        }
      }).catch((error) => {
      })

    },
    canOpenProfileForm() {
      if (this.reservation.location_id && (this.reservation.admin_id || this.reservation.no_admin) &&
          this.reservation.start_time &&
          this.reservation.courses.length &&
          (this.selectedCoursesPrice() > 0)
      ) {
        return true
      }

      return false
    },
    getClassDate(date){
      if(date["wday"] == "Sat"){
        return "date-saturday"
      }
      if(date["wday"] == "Sun"){
        return "date-sunday"
      }
    },
    getWday(date){
      let days = ["日","月","火","水","木","金","土"]
      return days[date["day_of_week"]]
    },
    getClassNavi(tab) {
      return this.selectTab != tab ? 'w-100 btn btn-outline-primary' : 'w-100 btn btn-primary'
    },
    backTask() {
      if(this.selectTab == "admin_or_date"){
        this.reset()
        this.init()
        this.selectTab = "location"
      }
      if(this.selectTab == "course"){
        this.init()
        this.fetchData()
        this.selectTab = "admin_or_date"
      }
      if(this.canShowProfileForm = true){
        this.canShowProfileForm = false
      }
    },
  },
  mounted() {
    this.fetchLocations()
    // this.fetchData()
    // this.fetchAdmin()
    this.openLocation()
  },
  computed: {
  }
}
</script>



<style scoped>
.bg-green{
  background: rgba(117,181,81, 0.1);
  border-radius: 0.5rem;
}
.border-green{
  border-radius: 1rem;
  border: 4px solid rgba(117,181,81, 1);
}
.form-control{
  border: 2px solid rgba(117,181,81, 1);
  color: #000;
  height: calc(1.5em + .9rem + 8px);
}
.form-row label{
  color: #000;
}

</style>