<template>
  <v-app>
    <v-main>


      <div class="calendar-shortcut">
        <button class="btn btn-info" @click="lastWeek()">
          前週
        </button>
        <button class="btn btn-info" @click="nextWeek()">
          次週
        </button>
        <button class="btn btn-info" @click="goToday()">
          今日の予定
        </button>
      </div>


      <v-row>
        <v-col cols="12">
          <template>
            <input class="form-control" placeholder="検索キーワードを入力してください" v-model="keyword" @keyup="getEvents"></input>
          </template>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12">
          <v-sheet
            tile
            class="d-flex">
            <v-btn
              color="primary"
              small
              elevation="0"
              class="mt-3"
              @click="$refs.calendar.prev()"
            >
              <v-icon>mdi-chevron-left</v-icon>前
            </v-btn>
            <v-select
              v-model="admin_id"
              :items="admins"
              item-text="name"
              item-value="id"
              dense
              outlined
              hide-details
              label="スタッフ選択"
              class="ma-2"
            ></v-select>
            <v-select
              v-model="location_id"
              :items="locations"
              item-text="name"
              item-value="id"
              dense
              outlined
              hide-details
              label="店舗選択"
              class="ma-2"
            ></v-select>
            <v-select
              v-model="type"
              :items="types"
              dense
              outlined
              hide-details
              class="ma-2"
              label="表示"
            ></v-select>
            <v-select
              v-model="weekday"
              :items="weekdays"
              dense
              outlined
              hide-details
              label="曜日"
              class="ma-2"
            ></v-select>
            <v-btn
              color="primary"
              small
              elevation="0"
              class="mt-3"
              @click="$refs.calendar.next()"
            >
            次
              <v-icon>mdi-chevron-right</v-icon>

            </v-btn>
          </v-sheet>
          <v-card-text class="d-flex align-center justify-center pa-4 mx-auto mb-2">
            <v-label>{{ current_month }}</v-label>
          </v-card-text>
          <v-sheet>
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              first-time="09:00"
              locale="ja"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              :weekdays="weekday"
              @click:time="showCreateDialog"
              @click:event="showEditDialog"
              @click:date="viewDay"
              @change="updateRange"
              @next="updateRange"
              @prev="updateRange"
            ></v-calendar>
          </v-sheet>
<!--
          <v-card-text class="text-right">
            <v-btn
                color="success"
                small
                elevation="0"
                @click="exportPDF()"
                :disabled="!admin_id || !current_month"
            >
              PDFエクスポート
              <v-icon right dark>mdi-cloud-download</v-icon>
            </v-btn>
          </v-card-text>
 -->
        </v-col>
      </v-row>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-y
        max-width="350"
      >
        <v-card color="grey lighten-4" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <a :href="`/admin/reservations/${selectedEvent.id}`" target="_blank" class="text-white">
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            </a>
            <div class="flex-grow-1"></div>
          </v-toolbar>

          <v-card-text>
            <b>時間:</b> {{ selectedEvent.time }}
            <hr>
            <div v-for="admin in selectedEvent.admins">
              <b>スタッフ:</b> {{ admin.name }}
            </div>
            <hr>
            <div v-for="course in selectedEvent.courses">
              <b>コース:</b> {{ course.title }}
            </div>
            <hr>
            <div v-if="selectedEvent.reservation_profile">
              <h6>お客さま情報</h6>
              <div><b>お名前:</b> {{ selectedEvent.reservation_profile.name}}</div>
              <div><b>電話番号:</b> {{ selectedEvent.reservation_profile.tel}}</div>
              <div><b>Email:</b> {{ selectedEvent.reservation_profile.email}}</div>
            </div>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              type="button"
              class="btn btn-light mr-1"
              text
              color="secondary"
              @click="selectedOpen = false"
            >閉じる</button>
            <button
              type="button"
              class="btn btn-primary"
              text
              v-if="currentlyEditing !== selectedEvent.id"
              @click.prevent="showEditDialog(selectedEvent.id)"
            >編集する</button>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-main>
    <CreateReservationDialog
      :modelId="selectedId"
      v-on:close-dialog="closeDialog"
      :selectedDate="selectedDate"
      :selectedHour="selectedHour"
      :selectedMin="selectedMin"
      v-model="displayFormDialog"
      @reloadList="getEvents" />
    <vue-element-loading :active="loading" :is-full-screen="true"></vue-element-loading>

  </v-app>
</template>

<script>
  import CreateReservationDialog from './Form.vue';
  import VueElementLoading       from 'vue-element-loading'
  export default {
    components: {
      CreateReservationDialog,
      VueElementLoading
    },
    data: () => ({
      loading: true,
      today: new Date().toISOString().substr(0, 10),
      focus: new Date().toISOString().substr(0, 10),
      type: 'month',
      types: [
        { text: '月', value: 'month' },
        { text: '週', value: 'week' },
        { text: '日', value: 'day' },
        { text: '4日', value: '4day' },
      ],
      mode: 'stack',
      modes: ['stack', 'column'],
      admin_id: null,
      admins: [],
      location_id: null,
      locations: [],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: '日曜日 - 土曜日', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: '月曜日 - 日曜日', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: '月曜日 - 金曜日', value: [1, 2, 3, 4, 5] },
        { text: '月曜日, 水曜日, 金曜日', value: [1, 3, 5] },
      ],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      value: '',
      events: [],
      currentlyEditing: null,
      displayFormDialog: false,
      displayConfirmDialog: false,
      selectedId: null,
      selectedDate: null,
      selectedHour: null,
      selectedMin: null,
      rangeStart: null,
      rangeEnd: null,
      current_month: null,
      keyword: null
    }),
    mounted () {
      this.initLocations()
      this.initAdmins()
      this.getAdmin()
      window.addEventListener('keyup', (event) => {
        if (event.keyCode === 27) {
          this.displayFormDialog = false
          this.selectedId = null
        }
      })
      this.directReservation()
    },
    methods: {
      directReservation(){
        const url = new URL(window.location.href)
        const params = url.searchParams
        if(!params.get('reservation')){
          return
        }
        this.selectedDate = params.get('date')
        this.selectedHour = parseInt(params.get('hour'))
        this.selectedMin  = params.get('min')
        this.displayFormDialog = true
      },
      closeDialog(){
        this.selectedId = null
      },
      async getEvents () {
        this.loading = true
        if(this.admin_id == null){
          return
        }
        let params = {
          start_date: this.rangeStart,
          end_date: this.rangeEnd,
          location_id: this.location_id,
          admin_id: this.admin_id,
          keyword: this.keyword,
          cancel: false
        }
        await this.axios.get('/api/v1/admin/a_types', {params}).then((response) => {
          setTimeout(() => {
            this.events = this.fetchEvent(response.data.reservations)
            this.loading = false
          }, 500)
        }).catch((error) => {
        })
      },
      viewDay ({ date }) {
        this.selectedDate = date
        this.type = "day"
      },
      lastWeek(){
        this.type = "week"
        this.$refs.calendar.prev()
      },
      nextWeek(){
        this.type = "week"
        this.$refs.calendar.next()
      },
      goToday(){
        const today = new Date()
        const year  = today.getFullYear()
        const month = today.getMonth() + 1
        const date  = today.getDate()
        this.selectedDate = `${year}-${month}-${date}`
        this.type = "day"
        this.setToday()
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      createEvent() {
        this.selectedId        = null
        this.selectedHour      = null
        this.selectedMin       = null
        this.displayFormDialog = true;
      },
      async deleteEvent (event_id) {
        await this.axios.delete(`/api/v1/admin/reservations/${event_id}`).then((response) => {
          this.getEvents()
        }).catch((error) => {
        })
      },
      showCreateDialog (event) {
        // this.selectedId = null
        let min = event.time.substring(3,10)
        if(min > 30){
          min = "30"
        } else {
          min = "00"
        }
        this.selectedDate = event.date
        this.selectedHour = event.hour
        this.selectedMin  = min
        this.displayFormDialog = true
      },
      showEditDialog ({ nativeEvent, event }) {
        this.selectedId        = event.id
        this.displayFormDialog = true
      },
      updateRange ({ start, end }) {
        this.rangeStart = start.date
        this.rangeEnd   = end.date
        this.getEvents()
        this.current_month = start.date.slice(0,-3)
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      fetchEvent(events) {
        let beauty_events = []
        $.each(events, (key, value) => {
          let start_at      =  new Date(value.start_at)
          let end_at        =  new Date(value.end_at)
          let start_at_text =  `${start_at.getHours().toString().padStart(2, '0')}時${start_at.getMinutes().toString().padStart(2, '0')}分`;
          let end_at_text =  `${end_at.getHours().toString().padStart(2, '0')}時${end_at.getMinutes().toString().padStart(2, '0')}分`;

          beauty_events.push({
            admins: value.admins,
            time: start_at_text + "〜" + end_at_text,
            name: value.title,
            start: start_at,
            end: end_at,
            color: value.color,
            timed: true,
            id: value.id,
          })
        })
        return beauty_events
      },
      async initLocations() {
        await this.axios.get('/api/v1/admin/locations').then((response) => {
          this.locations = response.data.locations
          this.locations.unshift([])
        }).catch((error) => {
        })
      },
      async initAdmins() {
        await this.axios.get('/api/v1/admin/admins').then((response) => {
          this.admins = response.data.admins
          this.admins.unshift([])
        }).catch((error) => {
        })
      },
      async getAdmin() {
        await this.axios.get('/api/v1/current_admin').then((response) => {
          this.admin_id = response.data.id
        }).catch((error) => {
        })
      },
      async exportPDF() {
        let actionURL = "/api/v1/admin/reservations/generate_pdf?admin_id=" + this.admin_id +
            "&month=" + this.current_month.split("-")[1];
        window.open(actionURL, "_blank");
      }
    },
    watch: {
      admin_id() {
        this.getEvents ()
      },
      location_id() {
        this.getEvents ()
      },
    }
  }
</script>
