<template>
  <div>
    <table class="table table-striped">
      <tr>
        <th>時間</th>
        <th>予約を受け付ける</th>
        <th>00分スタートを受け付ける</th>
        <th>15分スタートを受け付ける</th>
        <th>30分スタートを受け付ける</th>
        <th>45分スタートを受け付ける</th>
      </tr>
      <tr
        v-for="location_schedule in location_schedules"
        :class="{ 'table-dark': !location_schedule.allow_reservation }"
      >
        <th>{{ location_schedule.target_hour }}時</th>
        <td>
          <input
            @click="toggleCheckBox(location_schedule, 'allow_reservation')"
            type="checkbox"
            v-model="location_schedule.allow_reservation"
          />
        </td>
        <td>
          <input
            @click="toggleCheckBox(location_schedule, 'start_with_00_min')"
            type="checkbox"
            v-model="location_schedule.start_with_00_min"
          />
        </td>
        <td>
          <input
            @click="toggleCheckBox(location_schedule, 'start_with_15_min')"
            type="checkbox"
            v-model="location_schedule.start_with_15_min"
          />
        </td>
        <td>
          <input
            @click="toggleCheckBox(location_schedule, 'start_with_30_min')"
            type="checkbox"
            v-model="location_schedule.start_with_30_min"
          />
        </td>
        <td>
          <input
            @click="toggleCheckBox(location_schedule, 'start_with_45_min')"
            type="checkbox"
            v-model="location_schedule.start_with_45_min"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      location_schedules: [],
      location_schedule: {},
    };
  },
  mounted() {
    this.fetchLocationSchedules();
  },
  methods: {
    async fetchLocationSchedules() {
      await this.axios
        .get(location.href)
        .then((response) => {
          this.location_schedules = response.data;
        })
        .catch((error) => {});
    },
    toggleCheckBox(location_schedule, target) {
      this.$set(location_schedule, target, !location_schedule[target]);
      this.updateLocationSchedule(location_schedule);
    },
    updateLocationSchedule(location_schedule) {
      this.axios
        .post(location.href, location_schedule, {})
        .then((response) => {
          this.$toasted.show("更新しました！", {
            theme: "bubble",
            type: "success",
            position: "top-center",
            duration: 2000,
          });
        })
        .catch((error) => {
          this.$toasted.show("エラーが発生しました", {
            theme: "bubble",
            type: "error",
            position: "top-center",
            duration: 2000,
          });
        });
    },
  },
};
</script>

<style>
</style>
