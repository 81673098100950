<template>
  <div>
    <div class="row">
      <div class="col py-0">
        <div class="page-title-box">
          <h4 class="page-title text-black">予約する</h4>
        </div>
      </div>
    </div>
    <!-- <step-nav :reservation="reservation" :screenHistory="screenHistory" @backScreen="backScreen"></step-nav> -->
    <div class="col-12" v-if="canShowHistory()">
      <button class="btn btn-light" @click="backScreen">
        <i class="fas fa-chevron-left"></i>
        戻る
      </button>
    </div>

    <div class="row">
      <div class="col-12 col-md-9">
        <div class="card border-green">
          <div class="card-body">

            <div class="row" v-if="screen === 'location'">
              <div class="col-12">
                <select-locations :reservation="reservation" :locations="locations"
                  @setReservationData="setReservationData" @setScreen="setScreen">
                </select-locations>
              </div>
            </div>

            <div class="row" v-if="screen === 'process'">
              <div class="col-12">
                <select-process :screen="screen" @fetchAdmins="fetchAdmins" @fetchSchedules="fetchSchedules"
                  @setScreen="setScreen">
                </select-process>
              </div>
            </div>

            <div class="row" v-if="screen === 'admin'">
              <div class="col-12">
                <select-admins :screen="screen" :reservation="reservation" :admins="admins" @setScreen="setScreen"
                  @setReservationData="setReservationData" @fetchAdmins="fetchAdmins" @fetchSchedules="fetchSchedules"
                  @fetchCourses="fetchCourses" @backScreen="backScreen">
                </select-admins>
              </div>
            </div>

            <div class="row" v-if="screen === 'schedule'">
              <div class="col-12">
                <select-schedules :reservation="reservation" :schedules="schedules" :start_date="start_date"
                  @setScreen="setScreen" @setReservationData="setReservationData" @fetchCourses="fetchCourses"
                  @fetchAdmins="fetchAdmins" @setDateInterval="setDateInterval" @backScreen="backScreen">
                </select-schedules>
              </div>
            </div>

            <div class="row" v-if="screen === 'course'">
              <div class="col-12">
                <select-courses :reservation="reservation" :courses="courses" @setScreen="setScreen"
                  @setReservationData="setReservationData">
                </select-courses>
              </div>
            </div>

            <div class="row" v-if="screen === 'profile'">
              <div class="col-12">
                <profile-form :reservation="reservation" @setScreen="setScreen" @backScreen="backScreen"
                  @setResultData="setResultData">
                </profile-form>
              </div>
            </div>

            <div class="row" v-if="screen === 'complete'">
              <div class="col-12">
                <Complete :result="result" @setScreen="setScreen" @backScreen="backScreen">
                </Complete>
              </div>
            </div>

          </div>
        </div>
      </div>

      <side-bar :reservation="reservation" :selectedLocation="selectedLocation" :selectedMainAdmin="selectedMainAdmin"
        :selectedCourses="selectedCourses" :screenHistory="screenHistory" :screen="screen"
        @setReservationData="setReservationData" @setScreen="setScreen">
      </side-bar>
    </div>

    <div class="col-12" v-if="canShowHistory()">
      <button class="btn btn-light" @click="backScreen">
        <i class="fas fa-chevron-left"></i>
        戻る
      </button>
    </div>
    <vue-element-loading :active="loading" :is-full-screen="true"></vue-element-loading>
  </div>
</template>


<script>
import Vue from 'vue'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)
import VueElementLoading from 'vue-element-loading'
import StepNav from './StepNav.vue'
import SelectLocations from './SelectLocations.vue'
import SelectAdmins from './SelectAdmins.vue'
import SelectCourses from './SelectCourses.vue'
import SelectSchedules from './SelectSchedules.vue'
import SelectProcess from './SelectProcess.vue'
import ProfileForm from './ProfileForm.vue'
import Complete from './Complete.vue'
import SideBar from './SideBar.vue'
import booking from '../../../mixins/user/bookings/booking'
import fetchers from '../../../mixins/user/bookings/fetchers'

export default {
  mixins: [booking, fetchers],
  components: {
    VueElementLoading, StepNav, SelectLocations,
    SelectAdmins, SelectCourses, SelectSchedules,
    SelectProcess,
    ProfileForm, SideBar, Complete
  },
  data() {
    return {
      loading: false,
      reservation: {
        location_id: null,
        admin_id: null,
        start_time: null,
        end_time: null,
        course_ids: [],
        profile: {
          name: '',
          email: '',
          tel: '',
          comment: '',
        }
      },
      result: {},
      screen: 'location',
      screenHistory: ["location"],
      start_date: this.setStartDate(),
      locations: [],
      schedules: {},
      // allAdmins: [],
      admins: [],
      courses: [],
    }
  },
  methods: {
    setStartDate() {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('today')) {
        return urlParams.get('today')
      }
      return this.formatDate(new Date())
    },
    async setReservationData(reservation) {
      this.$set(this, 'reservation', reservation)

      // if(fetch){
      //   await this.fetchData()
      // }
    },
    async fetchData() {
      this.loading = true
      await this.fetchAdmins()
      // await this.fetchAllAdmins()
      await this.fetchSchedules()
      await this.fetchCourses()
      this.loading = false
    },
    setResultData(result) {
      this.$set(this, 'result', result)
      this.setScreen('complete')
    },
    async setScreen(screen) {
      this.screenHistory.push(screen)
      this.$set(this, 'screen', screen)
    },
    backScreen() {
      this.screenHistory.pop()
      this.$set(this, 'screen', this.screenHistory[this.screenHistory.length - 1])
    },
    async setDateInterval(intervalDay) {
      this.loading = true
      if (intervalDay == 0) {
        this.start_date = this.formatDate(new Date())
      } else {
        // this.start_date から intervalDay 日後の日付を取得
        const date = new Date(this.start_date)
        date.setDate(date.getDate() + intervalDay)
        this.start_date = this.formatDate(date)
      }
      await this.fetchSchedules()
      this.loading = false
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    canShowHistory() {
      if (this.screen == "complete") {
        return false
      }
      return this.screenHistory.length > 1
    }
  },
  async mounted() {
    await this.fetchLocations()
    // await this.fetchSchedules()
    // await this.fetchAdmins()
    // await this.fetchCourses()
  },
  computed: {
    selectedLocation() {
      return this.locations.find((location) => location.id === this.reservation.location_id) || {}
    },
    selectedMainAdmin() {
      return this.admins.find((admin) => admin.id === this.reservation.admin_id) || {}
    },
    selectedCourses() {
      return this.courses.filter((course) => this.reservation.course_ids.includes(course.id)) || []
    },
  }
}
</script>
