<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h5 class="text-black">コースを選ぶ</h5>
      </div>
    </div>

    <div class="row" v-if="courses.length">
      <div class="col-12 col-sm-12" v-for="(course) in courses" :key="course.id">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <img src="/images/logo.png" class="avatar-md rounded-circle mr-3 align-self-center" alt="ロゴ">
              <div class="media-body overflow-hidden">

                <h3 class="font-15 mt-0 mb-1 text-black">
                  {{ course.title }}
                  <small class="badge badge-info float-right" v-if="course.occupation">
                    <b>{{ course.occupation / 60 }}分</b>
                  </small>
                </h3>
                <p class="mb-0 pb-0 font-13 text-black pl-1" v-if="course.comment">
                  <i class="far fa-comment"></i>
                  {{ course.comment }}
                </p>

                <h3 class="mt-3 mb-1 text-danger text-right">
                  {{ course.price }}円
                </h3>

                <hr>

                <button class="btn btn-light btn-rounded float-right" v-if="reservation.course_ids.includes(course.id)"
                  @click="toggleCourse(course)">
                  <i class="fa fa-check"></i> コースを外す
                </button>

                <button class="btn btn-primary btn-rounded float-right" v-else
                  @click="toggleCourse(course)">
                  <i class="fa fa-check"></i> このコースを選択する
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="alert alert-danger">
        ご指定の条件で現在利用可能なコースはありません。条件を変えて実行して下さい。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'select-courses',
  props: {
    reservation: {
      type: Object,
      required: true
    },
    courses: {
      type: Array,
      required: true
    }
  },
  async mounted() {
  },
  methods: {
    toggleCourse(course) {
      if (this.reservation.course_ids.includes(course.id)) {
        this.reservation.course_ids = this.reservation.course_ids.filter(id => id !== course.id);
      } else {
        this.reservation.course_ids.push(course.id);
      }

      if(!this.reservation.course_ids.length) {
        return
      }

      this.reservation.end_time = this.calculateEndTime(this.reservation.course_ids);
      this.$emit('setScreen', "profile");
      this.$emit("setReservationData", this.reservation, false);
    },
    calculateEndTime(course_ids) {
      const startTime = new Date(this.reservation.start_time);
      const courses = this.courses.filter(course => course_ids.includes(course.id));
      const addTime = courses.reduce((acc, course) => acc + course.occupation, 0);
      let endTime = new Date(startTime.getTime() + addTime * 1000);
      // endTimeの1分前
      endTime = new Date(endTime.getTime() - 60 * 1000);
      return `${endTime.getFullYear()}-${("0" + (endTime.getMonth() + 1)).slice(-2)}-${("0" + endTime.getDate()).slice(-2)} ${("0" + endTime.getHours()).slice(-2)}:${("0" + endTime.getMinutes()).slice(-2)}`;
    }

  }
}
</script>
