<template>
  <div>

    <div class="row">
      <div class="col">
        <h5 class="text-black"><i class="fas fa-info-circle"></i> 予約者情報</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-12">

        <div class="form-row">
          <div class="col-12">
            <label for="name">
              <span class="text-danger">※</span>
              お名前
            </label>
            <input type="text" class="form-control" id="name" placeholder="山田 太郎" v-model="reservation.profile.name">
          </div>
        </div>

        <div class="form-row">
          <div class="col-12">
            <label for="tel">
              <span class="text-danger">※</span>
              電話番号
            </label>
            <input type="tel" class="form-control" id="tel" placeholder="09000000000" pattern="^[0-9]+$" maxlength="11" v-model="reservation.profile.tel">
          </div>
        </div>

        <div class="form-row">
          <div class="col-12">
            <label for="email"><span class="text-danger">※</span>メールアドレス</label>
          <input type="email" class="form-control" id="email" placeholder="info@example.com" v-model="reservation.profile.email">
          </div>
        </div>

        <div class="form-row">
          <div class="col-12">
            <label for="comment">現在お困りの症状など(250文字以内)</label>
            <textarea class="form-control" v-model="reservation.comment" rows="4"></textarea>
          </div>
        </div>

        <hr>

        <button
          @click="book()"
          class="mt-3 btn btn-primary btn-lg w-100 ripple-primary" type="button"><b>この内容で予約する</b>
        </button>

      </div>
    </div>

  </div>
</template>

<script>
import fetchers from '../../../mixins/user/bookings/fetchers'
export default {
  name: 'profile-form',
  mixins: [fetchers],
  props: {
    reservation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      errors: []
    }
  },
  async mounted() {
    await this.fetchUser()
    if(!this.canShowThisScreen()){
      alert("この予約は受付できません")
      this.$emit('backScreen')
    } else {
      this.checkAvailable()
    }
  },
  methods: {
    async canShowThisScreen(){
      if(!this.reservation.location_id){
        return false
      }
      if(!this.reservation.admin_id){
        return false
      }
      if(!this.reservation.start_time){
        return false
      }
      if(!this.reservation.course_id){
        return false
      }
      return true
    },
    checkAvailable(){
      const a = this.checkAvailability()
      a.then((res) => {
        if(!res.available){
          alert("この予約は受付できません")
          this.$emit('backScreen')
        }
      }).catch((err) => {
        alert("この予約は受付できません")
        this.$emit('backScreen')
      })
    },
    validate(){
      this.errors = []
      if(!this.reservation.profile.name){
        this.errors.push("お名前を入力してください")
      }
      if(!this.reservation.profile.tel){
        this.errors.push("電話番号を入力してください")
      }
      if(!this.reservation.profile.email){
        this.errors.push("メールアドレスを入力してください")
      }
      if(this.reservation.profile.email && !this.validateEmail(this.reservation.profile.email)){
        this.errors.push("メールアドレスの形式が正しくありません")
      }
      // if(this.reservation.profile.tel && !this.validateTel(this.reservation.profile.tel)){
      //   this.errors.push("電話番号の形式が正しくありません")
      // }
      if(this.reservation.profile.comment && this.reservation.profile.comment.length > 250){
        this.errors.push("コメントは250文字以内で入力してください")
      }
      return this.errors.length === 0
    },
    validateEmail(email){
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      return re.test(email)
    },
    validateTel(tel){
      const re = /^[0-9]+$/
      return re.test(tel)
    },
    book(){
      if(!this.validate()){
        this.$toasted.show(this.errors.join("。<br>"), {
          theme: "bubble",
          type: "error",
          position: "top-center",
          duration: 2000
        })
        return
      }

      this.axios.post('/api/v1/booking/reservations', this.reservation)
        .then(response => {
          this.$toasted.show("予約を完了しました！", {
            theme: "bubble",
            type: "success",
            position: "top-center",
            duration: 2000
          })
          this.$emit("setResultData", response.data.result)
        })
        .catch((error) => {
          setTimeout(() => {
            this.$toasted.show(error.response.data.error, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 2000
            })
          }, 200)
        }
      )
    }
  }
}
</script>
