<template>
  <div>

    <div class="row">
      <div class="col">
        <h5 class="text-black"><i class="fas fa-map-marked-alt"></i> 店舗を選択する</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-12" v-for="location in locations" :key="location.id">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <img src="/images/logo.png" class="avatar-md rounded-circle mr-3 align-self-center" alt="ロゴ">
              <div class="media-body overflow-hidden">

                <h3 class="font-16 mt-0 mb-1 text-black">
                  {{location.name}}
                  <span class="badge badge-info float-right" v-if="location.address">
                    <a :href="'https://www.google.com/maps/search/?api=1&query=' + location.address" target="_blank" class="text-white">
                      <i class="fas fa-external-link-alt"></i> <b>地図を見る</b>
                    </a>
                  </span>
                </h3>
                <p class="mb-0 pb-0 font-15 text-black pl-1" v-if="location.address">
                  <i class="fas fa-map-marker-alt"></i>
                  {{location.address}}
                </p>

                <p class="mb-0 pb-0 font-15 pl-1" v-if="location.tel">
                  <a :href="'tel:' + location.tel" class="text-black">
                    <i class="fas fa-phone"></i>
                    {{location.tel}}
                  </a>
                </p>

                <p class="mb-0 pb-0 font-15 pl-1 text-black" v-if="location.operation_time">
                  <i class="far fa-clock"></i> {{location.operation_time}}
                </p>

                <p class="mb-0 pb-0 font-15 pl-1" v-if="location.url">
                  <a :href="'tel:' + location.url" class="text-black">
                    <i class="fab fa-safari"></i>
                    {{location.url}}
                  </a>
                </p>

                <div class="row mt-2">
                  <div class="col-12 col-lg-4 offset-lg-8 col-md-6 offset-md-6">
                    <button class="btn btn-primary btn-block btn-rounded" @click="toggleLocation(location.id)" v-if="reservation.location_id !== location.id">
                      <i class="fa fa-check"></i>
                      この店舗を選ぶ
                    </button>
                    <button class="btn btn-light btn-block btn-rounded" @click="toggleLocation(location.id)" v-else>
                      <i class="fa fa-check"></i>
                      選択済み
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'select-loactions',
  data() {
    return {}
  },
  async mounted() {
  },
  props: {
    reservation: {
      type: Object,
      required: true
    },
    locations: {
      type: Array,
      required: true
    }
  },
  methods: {
    toggleLocation(locationId) {
      if (this.reservation.location_id === locationId) {
        this.reservation.location_id = null;
      } else {
        this.reservation.location_id = locationId;
      }
      this.$emit("setReservationData", this.reservation);
      if(this.reservation.location_id){
        this.$emit("setScreen", "process");
      }
    },
  }
}
</script>
