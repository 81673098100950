<template>
  <div>
    <div class="row">
      <div class="col">
        <h5 class="text-black"><i class="fa fa-calendar-alt"></i> 日程を選ぶ</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="d-sm-flex justify-content-between">
          <div>
            <h5 class="text-black">{{ schedules.year }}年{{ schedules.month }}月</h5>
          </div>
          <div>
            <button class="btn btn-light" @click="setStartDate(-30)">
              <i class="fas fa-angle-left"></i>
              前月
            </button>

            <button class="btn btn-light" @click="setStartDate(-7)">
              <i class="fas fa-angle-left"></i>
              前週
            </button>

            <button class="btn btn-primary" @click="setStartDate(0)">今日</button>

            <button class="btn btn-primary" @click="setStartDate(7)">
              次週 <i class="fas fa-angle-right"></i>
            </button>

            <button class="btn btn-primary" @click="setStartDate(30)">
              次月 <i class="fas fa-angle-right"></i>
            </button>
          </div>
        </div>

        <div class="table-responsive">

          <vue-element-loading :active="loadingTable" :is-full-screen="true"></vue-element-loading>
          <table class="table table-sm table-bordered schedule-table mt-2">
            <thead style="position: sticky;top: 0">
              <tr>
                <th></th>
                <td class="align-middle" :class="getClassDate(date)" v-for="( date, index ) in schedules.dates"
                  :key="index">
                  <span class="day">{{ date["day"] }}</span>
                  <span class="wday">({{ date["wday"] }})</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(time, index) in schedules.times" :key="index" class="text-black">
                <th class="align-middle time">{{ time }}</th>
                <td :key="i" v-for="( date, i ) in schedules.dates" class="align-middle">
                  <span class="selected" v-if="reservation.start_time === `${date.date} ${time}`">
                    <button class="mb-2" @click="setDateTime(date, time)">
                      <i class="far fa-check-circle"></i> <small>選択済</small>
                    </button>
                  </span>
                  <span class="not-available" v-else-if="!findScheduleByStartTime(date, time).availability">
                    <span class="mb-2">x</span>
                  </span>
                  <button v-else class="date-button btn btn-light mb-1" @click="setDateTime(date, time)">
                    <i class="far fa-circle mb-1"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueElementLoading from 'vue-element-loading'
export default {
  components: {
    VueElementLoading
  },
  name: 'select-schedules',
  props: {
    reservation: {
      type: Object,
      required: true
    },
    schedules: {
      type: Object,
      required: true
    },
  },
  computed: {
    loadingTable() {
      if (this.schedules && this.schedules.times && this.schedules.times.length !== 0) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    getClassDate(date) {
      if (date["wday"] == "土") {
        return "date-saturday"
      }
      if (date["wday"] == "日") {
        return "date-sunday"
      }
    },
    findScheduleByStartTime(date, time) {
      return this.schedules.schedules.find((schedule) => {
        return schedule.date === date.date
      }).times.find((t) => t.time === time)
    },
    setDateTime(date, time) {
      const datetime = `${date.date} ${time}`;
      this.reservation.start_time = datetime;
      if (this.reservation.admin_id) {
        this.$emit("fetchCourses")
        this.$emit("setScreen", "course");
      } else {
        this.$emit("fetchAdmins");
        this.$emit("setScreen", "admin");
      }
      this.$emit("setReservationData", this.reservation, false);
    },
    setStartDate(intervalDay) {
      this.$emit("setDateInterval", intervalDay);
    },
    backScreen() {
      this.$emit('backScreen')
    }
  },
}
</script>
