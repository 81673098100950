<template>

  <div id="new-reservation-sidebar" class="col-12 col-md-3">
    <div class="card border-green">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="bg-light text-black p-2">
              <h5 class="text-black text-center">- 現在の選択内容 -</h5>
              <hr>

              <div v-if="selectedLocation.name">
                <p class="mb-0 text-primary"><b><i class="fas fa-check-circle"></i> 店舗: {{ selectedLocation.name
                    }}</b></p>
              </div>

              <div v-else>
                <p class="mb-0"><b><i class="fas fa-check-circle text-danger"></i> 店舗:</b> 未選択 </p>
              </div>

              <div v-if="selectedMainAdmin.display_name && selectedMainAdmin.display_name.length">
                <p class="mb-0 text-primary"><b><i class="fas fa-check-circle"></i> スタッフ: {{
                  selectedMainAdmin.display_name }}</b></p>
              </div>

              <div v-else>
                <p class="mb-0"><b><i class="fas fa-check-circle text-danger"></i> スタッフ:</b> 未選択</p>
              </div>

              <div v-if="reservation.start_time">
                <p class="mb-0 text-primary">
                  <b>
                    <span><i class="fas fa-check-circle"></i> 日程: {{ reservation.start_time }}</span>
                    <span v-if="reservation.end_time">〜{{ reservation.end_time.slice(-5) }}</span>
                  </b>
                </p>
              </div>

              <div v-else>
                <p class="mb-0"><b><i class="fas fa-check-circle text-danger"></i> 日程:</b> 未選択</p>
              </div>

              <div v-if="selectedCourses.length">
                <p class="mb-0 text-primary">
                  <b><i class="fas fa-check-circle"></i>コース:
                    <span v-for="(course, index) in selectedCourses" :key="index">
                      {{ course.title }}{{ index !== selectedCourses.length - 1 ? ', ' : '' }}
                    </span>
                  </b>
                </p>
              </div>

              <div v-else>
                <p class="mb-0"><b><i class="fas fa-check-circle text-danger"></i> コース:</b> 未選択</p>
              </div>
              <hr>
            </div>

            <button class="btn btn-light btn-block" @click="resetReservation()">
              <i class="fas fa-undo"></i> 内容をリセットする
            </button>

            <!--
              <button class="mt-3 btn btn-primary btn-lg w-100 ripple-primary" type="button" @click="book()">
                <b>この内容で予約する</b>
              </button>

              <button v-if="screen == 'profile'" class="mt-3 btn btn-primary btn-lg w-100 ripple-primary" type="button">
                <b>この内容で予約する</b>
              </button> -->


          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import fetchers from '../../../mixins/user/bookings/fetchers'
export default {
  name: 'side-bar',
  mixins: [fetchers],
  props: {
    reservation: {
      type: Object,
      required: true
    },
    selectedLocation: {
      type: Object,
      required: true
    },
    selectedMainAdmin: {
      type: Object,
      required: true
    },
    selectedCourses: {
      type: Array,
      required: true
    },
    screenHistory: {
      type: Array,
      required: true
    },
    screen: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      resetReservationData: {
        location_id: null,
        admin_id: null,
        start_time: null,
        course_ids: [],
        profile: {
          name: '',
          email: '',
          tel: '',
          comment: '',
        }
      }
    }
  },
  methods: {
    resetReservation() {
      this.$emit("setReservationData", this.resetReservationData)
      this.$emit("setScreen", "location")
    }
  }
}
</script>
