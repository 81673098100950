<template>
  <v-row justify="center">
    <v-dialog
      v-model="displayDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>{{action}}してよろしいでしょうか</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button
            type="button"
            class="btn btn-light"
            text
            @click="displayDialog = false"
          >キャンセル</button>
          <button
            type="button"
            class="btn btn-primary"
            text
            @click="confirmAction"
          >確認</button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, required: true },
    action: {type: String, default: '削除'}
  },
  methods: {
    confirmAction() {
      this.$emit('processAction')
      this.displayDialog = false
    }
  },
  computed: {
    displayDialog: {
      get() { return this.value },
      set(value) {this.$emit('input', value)}
    },
  }
}
</script>

<style>

</style>
