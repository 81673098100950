import axios from "axios";
const csrfToken = document.querySelector("meta[name=csrf-token]").content;
const VueAxiosPlugin = {
  install(Vue) {
    axios.interceptors.request.use((config) => {
      config.headers["Content-Type"] = "application/json"
      config.headers["X-Requested-With"] = "XMLHttpRequest"
      config.headers["common"]["X-CSRF-Token"] = csrfToken
      return config
    }), (error) => {
      return Promise.reject(error)
    }
    axios.interceptors.response.use((response) => {
      return response;
    }), (error) => {
      return Promise.reject(error)
    }

    Object.defineProperties(Vue.prototype, {
      axios: {
        get () {
          return axios
        }
      }
    })
  }
}

export default VueAxiosPlugin
