<template>
  <v-app>
    <v-main>
      <v-row class="pa-3">
        <v-col cols="12">
          <v-sheet
            tile
            class="d-flex"
          >
            <v-btn
              color="primary"
              small
              elevation="0"
              class="mt-3"
              @click="$refs.calendar.prev()"
            >
              <v-icon>mdi-chevron-left</v-icon>前
            </v-btn>
            <v-select
              v-model="admin_id"
              :items="admins"
              item-text="name"
              item-value="id"
              dense
              outlined
              hide-details
              label="スタッフ選択"
              class="ma-2"
            ></v-select>
            <v-select
              v-model="location_id"
              :items="locations"
              item-text="name"
              item-value="id"
              dense
              outlined
              hide-details
              label="店舗選択"
              class="ma-2"
            ></v-select>
            <v-select
              v-model="type"
              :items="types"
              dense
              outlined
              hide-details
              class="ma-2"
              label="表示"
            ></v-select>
            <v-select
              v-model="weekday"
              :items="weekdays"
              dense
              outlined
              hide-details
              label="曜日"
              class="ma-2"
            ></v-select>
            <v-btn
              color="primary"
              small
              elevation="0"
              class="mt-3"
              @click="$refs.calendar.next()"
            >
            次
              <v-icon>mdi-chevron-right</v-icon>

            </v-btn>
          </v-sheet>
          <v-card-text class="d-flex align-center justify-center pa-4 mx-auto mb-2">
            <v-label>{{ current_month }}</v-label>
          </v-card-text>
          <v-sheet>
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              locale="ja"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              :weekdays="weekday"
              @click:event="showEvent"
              @click:date="viewDay"
              @change="updateRange"
              @next="updateRange"
              @prev="updateRange"
            ></v-calendar>
          </v-sheet>
          <v-card-text class="text-right">
            <v-btn
                color="success"
                small
                elevation="0"
                @click="exportPDF()"
                :disabled="!admin_id || !current_month"
            >
              PDFエクスポート
              <v-icon right dark>mdi-cloud-download</v-icon>
            </v-btn>
          </v-card-text>
        </v-col>
      </v-row>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-y
        max-width="350"
      >
        <v-card color="grey lighten-4" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-btn @click="showConfirmDialog(selectedEvent.id)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <a :href="`/admin/admins/${selectedEvent.admin_id}`" target="_blank" class="text-white">
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            </a>
            <div class="flex-grow-1"></div>
          </v-toolbar>

          <v-card-text>
            <form>
              {{ selectedEvent.description }}
            </form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              type="button"
              class="btn btn-light mr-1"
              text
              color="secondary"
              @click="selectedOpen = false"
            >閉じる</button>
            <button
              type="button"
              class="btn btn-primary"
              text
              v-if="currentlyEditing !== selectedEvent.id"
              @click.prevent="showEditDialog(selectedEvent.id)"
            >編集する</button>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-main>
    <TimeShiftDialog :modelId="selectedId" :selectedDate="selectedDate" v-model="displayFormDialog" @reloadList="getEvents" />
    <ConfirmDialog v-model="displayConfirmDialog" @processAction="deleteEvent(selectedId)"/>
    <vue-element-loading :active="loading" :is-full-screen="true"></vue-element-loading>
  </v-app>
</template>

<script>
  import TimeShiftDialog   from './Form.vue';
  import ConfirmDialog     from '../shared/ConfirmDialog.vue'
  import VueElementLoading from 'vue-element-loading'
  export default {
    components: {
      TimeShiftDialog,
      ConfirmDialog,
      VueElementLoading
    },
    data: () => ({
      loading: true,
      today: new Date().toISOString().substring(0, 10),
      focus: new Date().toISOString().substring(0, 10),
      type: 'month',
      types: [
        { text: '月', value: 'month' },
        { text: '週', value: 'week' },
        { text: '日', value: 'day' },
        { text: '4日', value: '4day' },
      ],
      mode: 'stack',
      modes: ['stack', 'column'],
      admin_id: null,
      admins: [],
      location_id: null,
      locations: [],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: '日曜日 - 土曜日', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: '月曜日 - 日曜日', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: '月曜日 - 金曜日', value: [1, 2, 3, 4, 5] },
        { text: '月曜日, 水曜日, 金曜日', value: [1, 3, 5] },
      ],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      value: '',
      events: [],
      currentlyEditing: null,
      displayFormDialog: false,
      displayConfirmDialog: false,
      selectedId: null,
      selectedDate: null,
      rangeStart: null,
      rangeEnd: null,
      current_month: null,
    }),
    mounted () {
      this.setDefaultDateRange()
      this.initLocations()
      this.initAdmins()
      window.addEventListener('keyup', (event) => {
        if (event.keyCode === 27) {
          this.displayFormDialog = false;
        }
      })
    },
    methods: {
      setDefaultDateRange(){
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.has('today')) {
          this.focus = urlParams.get('today')
        }
      },
      async getEvents () {
        this.loading = true
        let params = {
          start_date: this.rangeStart,
          end_date: this.rangeEnd,
          location_id: this.location_id,
          admin_id: this.admin_id
        }
        await this.axios.get('/api/v1/admin/shift_tables', {params}).then((response) => {
          setTimeout(() => {
            this.events = this.fetchEvent(response.data.shift_tables)
            this.loading = false
          }, 500)
        }).catch((error) => {
        })
      },
      viewDay ({ date }) {
        this.selectedDate = date;
        this.createEvent();
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      createEvent() {
        this.selectedId = null
        this.displayFormDialog = true;
      },
      async deleteEvent (event_id) {
        await this.axios.delete(`/api/v1/admin/shift_tables/${event_id}`).then((response) => {
          this.getEvents()
        }).catch((error) => {
        })
      },
      showConfirmDialog(event_id) {
        this.displayConfirmDialog = true
        this.selectedId = event_id
      },
      showEditDialog(event_id) {
        this.selectedId = event_id
        this.displayFormDialog = true
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent   = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => this.selectedOpen = true, 10)
        }
        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }
        nativeEvent.stopPropagation()
      },
      updateRange ({ start, end }) {
        this.rangeStart = start.date
        this.rangeEnd = end.date
        this.getEvents()
        this.current_month = start.date.slice(0,-3)
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      fetchEvent(events) {
        let beauty_events = []
        $.each(events, (key, value) => {
          beauty_events.push({
            admin_id: value.admin.id,
            name: value.admin.name + '（' + value.location.name + '）',
            start: new Date(value.start_at),
            end: new Date(value.end_at),
            color: value.admin.shift_table_color,
            timed: true,
            id: value.id,
            description: value.description,
          })
        })
        return beauty_events
      },
      async initLocations() {
        await this.axios.get('/api/v1/admin/locations').then((response) => {
          this.locations = response.data.locations
          this.locations.unshift([])
        }).catch((error) => {
        })
      },
      async initAdmins() {
        await this.axios.get('/api/v1/admin/admins').then((response) => {
          this.admins = response.data.admins
          this.admins.unshift([])
        }).catch((error) => {
        })
      },
      async exportPDF() {
        let actionURL = "/api/v1/admin/shift_tables/generate_pdf?admin_id=" + this.admin_id +
            "&month=" + this.current_month.split("-")[1];
        window.open(actionURL, "_blank");
      }
    },
    watch: {
      admin_id() {
        this.getEvents ()
      },
      location_id() {
        this.getEvents ()
      },
    }
  }
</script>
